import React, { Fragment, useEffect, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';

import { userFullName, companyFullName, loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';
import {
    useCurrentUserQuery,
    useGetCompanyQuery,
    useCurrentUsersBoatsListQuery,
    useBoatDockingDistrictQuery,
    useGetAdministrativeDivisionsList,
    useProducersOrgQuery
} from '@SUPPORT/queries';
import { useDismissModalDialog, useModalDialogState, useOpenModalDialog } from '@STORES/licensee';

import { CancelButton, NextButton } from './common';
import { Button } from '@COMPONENTS/licensee/common/Button';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import { Overlay, DialogBox, ModalTitle, ExitZone, Content } from '@COMPONENTS/licensee/common/Modal';
import { TableTwoColumns } from '@COMPONENTS/licensee/common/TableTwoColumns';

import {
    Check,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle,
    Info
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

const Requester = styled.strong`
    color: black;
    font-weight: 700;
`;

const Note = styled.p`
    width: 100%;
    color: #6c6c6c;
`;

export function RequestBoat() {
    const history = useHistory();
    const { seasonality } = useParams();
    const [selectedBoat, setSelectedBoat] = useState();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);

    const { data: user } = useCurrentUserQuery();
    const { data: company } = useGetCompanyQuery(
        currentRequest ? currentRequest.requestAuthor.companyTelecapecheId : null
    );

    const { data: userBoats = [] } = useCurrentUsersBoatsListQuery();
    const boats = useMemo(
        () =>
            userBoats.filter(
                (boat) =>
                    !!currentRequest &&
                    boat.relationship.companyId === currentRequest.requestAuthor.companyTelecapecheId
            ),
        [userBoats, currentRequest]
    );

    const { data: divisions = [] } = useGetAdministrativeDivisionsList();
    const district = useBoatDockingDistrictQuery(selectedBoat ? selectedBoat.registrationDistrict : null);

    useEffect(() => {
        if (boats.length === 0) {
            return;
        }

        if (currentRequest && currentRequest.boat) {
            const info = boats.find((info) => info.boat.id === currentRequest.boat.telecapecheId);
            if (info) {
                setSelectedBoat(info.boat);
            }
        }
    }, [currentRequest, boats]);

    const onBack = useCallback(() => {
        if (currentRequest) {
            delete currentRequest.boat;
            saveTemporaryPEPRequest(currentRequest);
        }
    }, [currentRequest]);

    const next = useCallback(() => {
        if (currentRequest && currentRequest.boat) {
            history.push(`/request/pep/${seasonality}/license`);
            return;
        }

        const request = currentRequest || {
            depositDate: new Date(),
            requestAuthor: {
                telecapecheId: user.id,
                companyTelecapecheId: null
            }
        };

        const division = divisions.find((div) => div.code === district.code);
        const length = selectedBoat.size ? selectedBoat.size.value : 0;
        const motorPower = selectedBoat.motor
            ? selectedBoat.motor.unit === 'kw'
                ? selectedBoat.motor.value * 1000
                : selectedBoat.motor.value * 735.499
            : 0;

        request.boat = {
            telecapecheId: selectedBoat.id,
            name: selectedBoat.name,
            administrativeDivisionPath: division.administrativeDivisionPath,
            length,
            motorPower,
            registration: selectedBoat.registration,
            registrationDistrictCode: district.code,
            navigationCategory: selectedBoat.navCategory
        };

        saveTemporaryPEPRequest(request);
        history.push(`/request/pep/${seasonality}/license`);
    }, [user, currentRequest, divisions, district, selectedBoat, history, seasonality]);

    return (
        <Fragment>
            <Main>
                <TopBackButton onBack={onBack} />
                <Intro>
                    <Title>Choix du navire</Title>
                    <SubTitle>
                        <Requester>
                            Armateur : {company ? companyFullName(company) : userFullName(user, false)}
                        </Requester>
                    </SubTitle>
                    <SubTitle>
                        Sélectionnez le navire pour lequel vous souhaitez déposer une demande de licence. Si vous
                        souhaitez déposer plusieurs demandes de licences pour le même navire, vous devrez les saisir une
                        par une.
                    </SubTitle>
                </Intro>
                <BigButtonGrid withMargin>
                    {boats.map((info) => (
                        <BoatButton
                            request={currentRequest}
                            boat={info.boat}
                            isEnabled={
                                currentRequest &&
                                info.relationship.companyId === currentRequest.requestAuthor.companyTelecapecheId
                            }
                            isSelected={!!selectedBoat && selectedBoat.id === info.boat.id}
                            onClick={() => setSelectedBoat(info.boat)}
                            key={info.boat.id}
                        />
                    ))}
                </BigButtonGrid>
            </Main>
            <Footer>
                <CancelButton />
                <NextButton primary disabled={!selectedBoat || divisions.length === 0} onClick={next}>
                    Suivant
                </NextButton>
            </Footer>

            <BoatInfoDialog show={false} boatId={selectedBoat} />
        </Fragment>
    );
}

const BoatButton = ({ request, boat, isEnabled, isSelected, onClick }) => {
    const openModalDialog = useOpenModalDialog();
    const showBoatInfo = useCallback(
        (evt) => {
            evt.stopPropagation();
            openModalDialog('BOAT-INFO', boat);
        },
        [boat, openModalDialog]
    );

    const disabled = !isEnabled || (request && !!request.uniqueId);

    return (
        <ItemSelectionWithCheck
            itemSelected={isSelected}
            onClick={disabled ? null : onClick}
            className={disabled ? 'disabled' : null}
        >
            <Check />
            <Info onClick={showBoatInfo} />
            <ItemSelectionTitle>{boat.name}</ItemSelectionTitle>
            <MainText>{boat.registration}</MainText>
        </ItemSelectionWithCheck>
    );
};

const STATUSES = { active: 'actif', inactive: 'inactif', destroyed: 'détruit', sold: 'vendu' };

const BoatInfoDialog = () => {
    const { key, data: boat } = useModalDialogState();
    const dismiss = useDismissModalDialog();
    const show = key === 'BOAT-INFO';

    const producersOrg = useProducersOrgQuery(boat ? boat.producersOrg : null);
    const dockingDistrict = useBoatDockingDistrictQuery(boat ? boat.dockingDistrict : null);

    return (
        <Overlay show={show}>
            {boat && (
                <DialogBox>
                    <ModalTitle>{boat.name}</ModalTitle>
                    <Content>
                        <TableTwoColumns>
                            <tbody>
                                <tr>
                                    <td>Quartier Maritime</td>
                                    <td className="bold">
                                        {boat.registration_district_code} {boat.registration_district_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Immatriculation</td>
                                    <td className="bold">{boat.registration}</td>
                                </tr>
                                <tr>
                                    <td>Nom</td>
                                    <td className="bold">{boat.name}</td>
                                </tr>
                                <tr>
                                    <td>Taille</td>
                                    <td className="bold">{boat.size ? `${boat.size.value}${boat.size.unit}` : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Puissance motrice</td>
                                    <td className="bold">
                                        {boat.motor ? `${boat.motor.value}${boat.motor.unit}` : '-'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Jauge brute</td>
                                    <td className="bold">
                                        {boat.capacity
                                            ? `${boat.capacity.value} ${boat.capacity.unit.toUpperCase()}`
                                            : '-'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Statut</td>
                                    <td className="bold">{STATUSES[boat.status] || '-'}</td>
                                </tr>
                                <tr>
                                    <td>Année de construction</td>
                                    <td className="bold">{boat.yearBuilt || '-'}</td>
                                </tr>
                                <tr>
                                    <td>Année d&apos;acquisition</td>
                                    <td className="bold">{boat.yearAcquired || '-'}</td>
                                </tr>
                                <tr>
                                    <td>Catégorie de navigation</td>
                                    <td className="bold">{boat.navCategory || '-'}</td>
                                </tr>
                                <tr>
                                    <td>Organisation de producteurs</td>
                                    <td className="bold">{producersOrg ? producersOrg.name : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Systèmes embarqués</td>
                                    <td className="bold">
                                        {[boat.hasVMS ? 'vms' : '', boat.hasLogbook ? 'logbook' : '']
                                            .filter(Boolean)
                                            .join(', ') || '-'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Système de géolocalisation</td>
                                    <td className="bold">{boat.geolocator || '-'}</td>
                                </tr>
                                <tr>
                                    <td>Port de débarquement</td>
                                    <td className="bold">{dockingDistrict ? dockingDistrict.name : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Effectif de l&apos;équipage</td>
                                    <td className="bold">{boat.crewSize || '-'}</td>
                                </tr>
                            </tbody>
                        </TableTwoColumns>
                        <Note>Si vous trouvez une erreur ci-dessus, merci de contacter votre CDPMEM.</Note>
                    </Content>
                    <ExitZone>
                        <Button onClick={() => dismiss()}>Ok</Button>
                    </ExitZone>
                </DialogBox>
            )}
        </Overlay>
    );
};
