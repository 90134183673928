import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const Container = styled.button`
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-width: 7.5rem;
    height: var(--form-elements-height);
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    margin: 0;
    font: var(--font-button);
    color: #fff;
    border: none;
    border-radius: var(--form-elements-border-radius);
    cursor: pointer;
    overflow: visible;
    background-color: ${({ primary }) => (primary ? 'var(--orange)' : 'var(--dark-blue)')};
    box-sizing: border-box;

    &[disabled] {
        color: var(--super-light-gray);
        background: var(--disabled-color);
        cursor: default;
    }
`;

export function Button({ children, ...props }) {
    return <Container {...props}>{children}</Container>;
}

// BACK BUTTON

const StyledBackButton = styled(Button)`
    margin-inline-end: auto;
`;

export function BackButton() {
    const history = useHistory();
    const goBack = useCallback(
        (evt) => {
            evt.preventDefault();
            history.goBack();
        },
        [history]
    );

    return <StyledBackButton onClick={goBack}>Retour</StyledBackButton>;
}
