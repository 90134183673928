import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { useSessionQuery, useCurrentUserQuery, useCurrentUserCompaniesListQuery } from '@SUPPORT/queries';

import { Main, Intro, Title, MainText } from '@COMPONENTS/licensee/common/Main';
import { ItemSelection, ItemSelectionTitle, BigButtonGrid, Chevron } from '@COMPONENTS/licensee/common/BigButton';

export function Welcome() {
    const { data: session } = useSessionQuery();
    const history = useHistory();

    const { data: user, isFetched: isUserFetched } = useCurrentUserQuery(true);
    const { data: companies = [], isFetched: isCompaniesFetched } = useCurrentUserCompaniesListQuery(
        user ? user.id : null
    );

    const jumpToProfile = () => {
        if (!isUserFetched || !isCompaniesFetched) {
            return;
        }

        if (companies.length === 0) {
            history.push('/profile/user');
        } else {
            history.push('/profile/select');
        }
    };

    return (
        <Main>
            <Intro>
                <Title>Madame, Monsieur,</Title>
                <MainText>
                    Pescalice vous permet de connaitre en temps réel le statut de vos licences et de faire des demandes.
                </MainText>
            </Intro>
            <BigButtonGrid withMargin>
                <ItemSelection onClick={jumpToProfile}>
                    <Chevron />
                    <ItemSelectionTitle>Informations personnelles et professionnelles</ItemSelectionTitle>
                    <MainText>
                        Retrouvez les informations vous concernant, vos armements, ainsi que vos coordonnées bancaires.
                    </MainText>
                </ItemSelection>

                <ItemSelection onClick={() => history.push('/status')}>
                    <Chevron />
                    <ItemSelectionTitle>Campagnes en cours</ItemSelectionTitle>
                    <MainText>Consultez le statut de vos licences ou timbres.</MainText>
                </ItemSelection>

                <ItemSelection onClick={() => history.push('/request')}>
                    <Chevron />
                    <ItemSelectionTitle>Campagnes à venir</ItemSelectionTitle>
                    <MainText>Déposez ou modifier vos demandes de licence pour les campagnes à venir.</MainText>
                </ItemSelection>

                {/*
                <ItemSelection className="disabled">
                    <Chevron />
                    <ItemSelectionTitle>Renouvellements & Demandes</ItemSelectionTitle>
                    <MainText>
                        Vous devez avoir rempli vos <a href="#" onClick={() => history.push('/profile')}>informations bancaires</a> pour utiliser cette section.
                    </MainText>
                </ItemSelection>
                */}
            </BigButtonGrid>
        </Main>
    );
}
