import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { getRequestTypeLabel } from '@STORES/common/pescalice';

import { api } from '@SUPPORT/api';
import { formatLocaleDate, loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';
import {
    useCurrentUserQuery,
    useCreatePEPRequest,
    useUpdatePEPRequest,
    useGetCurrentPEPProxy,
    useGetBoatQuery,
    useGetCompanyQuery,
    useGetUserPEPRequests
} from '@SUPPORT/queries';

import { CancelButton, NextButton, pricingsDetail } from './common';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Section, Intro, Title } from '@COMPONENTS/licensee/common/Main';
import {
    Check,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

export function RequestAdmin() {
    const history = useHistory();
    const { seasonality } = useParams();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);
    const currentProxy = useGetCurrentPEPProxy(currentRequest);
    const additionalMetadata = useMemo(
        () => (currentProxy ? currentProxy.seasons[0].license.additionalMetadata : {}) || {},
        [currentProxy]
    );

    const { data: boat } = useGetBoatQuery(currentRequest ? currentRequest.boat.telecapecheId : null);
    const { data: company } = useGetCompanyQuery(
        currentRequest ? currentRequest.requestAuthor.companyTelecapecheId : null
    );

    const baseYear = new Date().getFullYear();
    const { data: user } = useCurrentUserQuery();
    const { data: requests = [], isLoading: isLoadingRequests } = useGetUserPEPRequests(
        user ? user.id : null,
        baseYear + 1,
        'seasonal'
    );

    const [knowsDeliberationsForTheSeason, setKnowsDeliberationsForTheSeason] = useState(false);
    const [paymentsToProfessionalOrganizationsDone, setPaymentsToProfessionalOrganizationsDone] = useState(false);
    const [boatPermitIsValid, setBoatPermitIsValid] = useState(false);
    const [hasGivenFishingStatisticsForPreviousSeason, setHasGivenFishingStatisticsForPreviousSeason] = useState(false);
    const [boatRegistrationIsFrench, setBoatRegistrationIsFrench] = useState(false);
    const [critereDebileA, setCritereDebileA] = useState(false); // not stored in Pescalice.
    const [critereDebileB, setCritereDebileB] = useState(false); // not stored in Pescalice.
    const [ownsLicenseCSJNationale, setOwnsLicenseCSJNationale] = useState(false);
    const [diversAllowedCertificate, setDiversAllowedCertificate] = useState(false);
    const [allowsOctopusCaptureDataCommunication, setAllowsOctopusCaptureDataCommunication] = useState(false);

    useEffect(() => {
        if (currentRequest && !isLoadingRequests) {
            const force = requests.length > 0;
            const reqs = currentRequest.administrativeRequirement || {};
            setKnowsDeliberationsForTheSeason(!!reqs.knowsDeliberationsForTheSeason || force);
            setPaymentsToProfessionalOrganizationsDone(!!reqs.paymentsToProfessionalOrganizationsDone || force);
            setBoatPermitIsValid(!!reqs.boatPermitIsValid || force);
            setHasGivenFishingStatisticsForPreviousSeason(!!reqs.hasGivenFishingStatisticsForPreviousSeason || force);
            setBoatRegistrationIsFrench(!!reqs.boatRegistrationIsFrench || force);
            setCritereDebileA(force); // not stored in Pescalice.
            setCritereDebileB(force); // not stored in Pescalice.
            setOwnsLicenseCSJNationale(!!reqs.ownsLicenseCSJNationale || force);
            setDiversAllowedCertificate(!!reqs.diversAllowedCertificate || force);
            setAllowsOctopusCaptureDataCommunication(!!reqs.allowsOctopusCaptureDataCommunication || force);
        }
    }, [currentRequest, requests, isLoadingRequests]);

    const finishSave = useCallback(
        (req) => {
            sendPEPLicenseRequestReceipt(user, company, req, currentProxy, boat, seasonality).then(() => {
                saveTemporaryPEPRequest(req);
                history.push(`/request/pep/${seasonality}/recap`);
            });
        },
        [user, boat, currentProxy, history, seasonality]
    );

    const creation = useCreatePEPRequest();
    const update = useUpdatePEPRequest();
    const saveRequest = useCallback(() => {
        if (!currentRequest) {
            alert('Erreur inconnue');
            history.push('/');
            return;
        }

        const data = {
            depositDate: currentRequest.depositDate,

            isFirstInstallation: currentRequest.isFirstInstallation,
            isLicenseRenewal: currentRequest.isLicenseRenewal,
            isNewLicenseRequest: currentRequest.isNewLicenseRequest,

            administrativeRequirement: {
                knowsDeliberationsForTheSeason,
                paymentsToProfessionalOrganizationsDone,
                boatPermitIsValid,
                hasGivenFishingStatisticsForPreviousSeason,
                boatRegistrationIsFrench
            }
        };

        if (
            additionalMetadata.administrativeRequirementsAdditions &&
            additionalMetadata.administrativeRequirementsAdditions.ownsLicenseCSJNationale
        ) {
            data.administrativeRequirement.ownsLicenseCSJNationale = ownsLicenseCSJNationale;
        }

        if (
            additionalMetadata.administrativeRequirementsAdditions &&
            additionalMetadata.administrativeRequirementsAdditions.diversAllowedCertificate
        ) {
            data.administrativeRequirement.diversAllowedCertificate = diversAllowedCertificate;
        }

        if (
            additionalMetadata.administrativeRequirementsAdditions &&
            additionalMetadata.administrativeRequirementsAdditions.allowsOctopusCaptureDataCommunication
        ) {
            data.administrativeRequirement.allowsOctopusCaptureDataCommunication = allowsOctopusCaptureDataCommunication;
        }

        if (currentRequest.additionalMetadata) {
            data.additionalMetadata = { ...currentRequest.additionalMetadata };
        }

        if (currentRequest.targetedZones) {
            data.targetedZones = currentRequest.targetedZones;
            data.obtainedZones = currentRequest.obtainedZones;
        }

        if (currentRequest.divers) {
            data.divers = currentRequest.divers;
        }

        if (currentRequest.uniqueId) {
            data.boat = currentRequest.boat.telecapecheId;
            update.mutate(
                { id: currentRequest.uniqueId, data },
                {
                    onSuccess: (res) => finishSave(res.data),
                    onError: (err) => alert(err.wrapped ? err.wrapped.errorMessage : err)
                }
            );
        } else {
            data.depositDate = new Date().toISOString();
            data.license = currentRequest.license;
            data.boat = { ...currentRequest.boat };
            data.requestAuthor = { ...currentRequest.requestAuthor };
            creation.mutate(data, {
                onSuccess: (res) => finishSave(res.data),
                onError: (err) => alert(err.wrapped ? err.wrapped.errorMessage : err)
            });
        }
    }, [
        currentRequest,
        additionalMetadata,
        creation,
        update,
        finishSave,
        knowsDeliberationsForTheSeason,
        paymentsToProfessionalOrganizationsDone,
        boatPermitIsValid,
        hasGivenFishingStatisticsForPreviousSeason,
        boatRegistrationIsFrench,
        ownsLicenseCSJNationale,
        diversAllowedCertificate,
        allowsOctopusCaptureDataCommunication
    ]);

    const canGoForward =
        knowsDeliberationsForTheSeason &&
        paymentsToProfessionalOrganizationsDone &&
        boatPermitIsValid &&
        hasGivenFishingStatisticsForPreviousSeason &&
        boatRegistrationIsFrench &&
        critereDebileA && // not stored in Pescalice.
        critereDebileB && // not stored in Pescalice.
        (!additionalMetadata ||
            !additionalMetadata.administrativeRequirementsAdditions ||
            (additionalMetadata.administrativeRequirementsAdditions &&
                !additionalMetadata.administrativeRequirementsAdditions.allowsOctopusCaptureDataCommunication) ||
            (additionalMetadata.administrativeRequirementsAdditions &&
                additionalMetadata.administrativeRequirementsAdditions.allowsOctopusCaptureDataCommunication &&
                allowsOctopusCaptureDataCommunication));

    return (
        <Main>
            <TopBackButton />
            <Section>
                <Intro>
                    <Title>Critères administratifs</Title>
                </Intro>
                <BigButtonGrid withMargin>
                    <ItemSelectionWithCheck
                        itemSelected={knowsDeliberationsForTheSeason}
                        onClick={() => setKnowsDeliberationsForTheSeason((v) => !v)}
                    >
                        <Check />
                        <ItemSelectionTitle>
                            J&apos;atteste avoir pris connaissance des délibérations et des conditions
                            d&apos;éligibilité aux licences
                        </ItemSelectionTitle>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={paymentsToProfessionalOrganizationsDone}
                        onClick={() => setPaymentsToProfessionalOrganizationsDone((v) => !v)}
                    >
                        <Check />
                        <ItemSelectionTitle>
                            J&apos;atteste être à jour des paiements des contributions professionnelles obligatoires
                            dues aux différents organismes professionnels
                        </ItemSelectionTitle>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={boatPermitIsValid}
                        onClick={() => setBoatPermitIsValid((v) => !v)}
                    >
                        <Check />
                        <ItemSelectionTitle>
                            J&apos;atteste réaliser une demande pour un navire détenteur d&apos;un PME, actif au fichier
                            flotte européen et dont le permis de navigation est en cours de validité
                        </ItemSelectionTitle>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={hasGivenFishingStatisticsForPreviousSeason}
                        onClick={() => setHasGivenFishingStatisticsForPreviousSeason((v) => !v)}
                    >
                        <Check />
                        <ItemSelectionTitle /* Ils ont changé de critères admin tellement de fois que plus rien ne colle avec le backend pescalice */
                        >
                            J&apos;atteste que les conditions d&apos;exploitation du navire sont conformes à la licence
                            demandée
                        </ItemSelectionTitle>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={boatRegistrationIsFrench}
                        onClick={() => setBoatRegistrationIsFrench((v) => !v)}
                    >
                        <Check />
                        <ItemSelectionTitle /* Ils ont changé de critères admin tellement de fois que plus rien ne colle avec le backend pescalice */
                        >
                            J&apos;atteste être à jour de mes obligations déclaratives
                        </ItemSelectionTitle>
                    </ItemSelectionWithCheck>

                    <ItemSelectionWithCheck itemSelected={critereDebileA} onClick={() => setCritereDebileA((v) => !v)}>
                        <Check />
                        <ItemSelectionTitle /* Ils ont changé de critères admin tellement de fois que plus rien ne colle avec le backend pescalice */
                        >
                            Je m&apos;engage à transmettre au CRPMEM, le certificat d&apos;enregistrement, le permis de
                            navigation le permis d&apos;armement et la licence européenne de pêche de mon navire
                        </ItemSelectionTitle>
                    </ItemSelectionWithCheck>

                    <ItemSelectionWithCheck itemSelected={critereDebileB} onClick={() => setCritereDebileB((v) => !v)}>
                        <Check />
                        <ItemSelectionTitle /* Ils ont changé de critères admin tellement de fois que plus rien ne colle avec le backend pescalice */
                        >
                            J&apos;autorise de manière permanente, l&apos;accès du CRPMEM de Bretagne, dans le cadre de
                            ses missions, aux informations et caractéristiques de mon activité de pêche, détenues par
                            des entités tierces publiques ou privées, y compris les données de capture, de vente et de
                            géolocalisation
                        </ItemSelectionTitle>
                    </ItemSelectionWithCheck>

                    {additionalMetadata.administrativeRequirementsAdditions &&
                        additionalMetadata.administrativeRequirementsAdditions.ownsLicenseCSJNationale && (
                            <ItemSelectionWithCheck
                                itemSelected={ownsLicenseCSJNationale}
                                onClick={() => setOwnsLicenseCSJNationale((v) => !v)}
                            >
                                <Check />
                                <ItemSelectionTitle>
                                    J&apos;atteste posséder la licence CSJ nationale
                                </ItemSelectionTitle>
                            </ItemSelectionWithCheck>
                        )}

                    {additionalMetadata.administrativeRequirementsAdditions &&
                        additionalMetadata.administrativeRequirementsAdditions.diversAllowedCertificate && (
                            <ItemSelectionWithCheck
                                itemSelected={diversAllowedCertificate}
                                onClick={() => setDiversAllowedCertificate((v) => !v)}
                            >
                                <Check />
                                <ItemSelectionTitle>
                                    Je m&apos;engage à joindre les certificats plongeurs (autorisation admin.)
                                </ItemSelectionTitle>
                            </ItemSelectionWithCheck>
                        )}

                    {additionalMetadata.administrativeRequirementsAdditions &&
                        additionalMetadata.administrativeRequirementsAdditions
                            .allowsOctopusCaptureDataCommunication && (
                            <ItemSelectionWithCheck
                                itemSelected={allowsOctopusCaptureDataCommunication}
                                onClick={() => setAllowsOctopusCaptureDataCommunication((v) => !v)}
                            >
                                <Check />
                                <ItemSelectionTitle>
                                    Je déclare autoriser le ministère en charge des pêches (DGAMPA) à communiquer au
                                    CRPMEM mes données individuelles de captures de poulpes
                                </ItemSelectionTitle>
                            </ItemSelectionWithCheck>
                        )}
                </BigButtonGrid>
            </Section>
            <Footer>
                <CancelButton />
                <NextButton primary disabled={!canGoForward} onClick={saveRequest}>
                    Suivant
                </NextButton>
            </Footer>
        </Main>
    );
}

function sendPEPLicenseRequestReceipt(user, company, request, proxy, boat, seasonality) {
    const season = proxy.seasons.find((season) => season.license.uniqueId === request.license.uniqueId);
    const seasonParts = season.parts || [];
    const lastSeasonPart = seasonParts[seasonParts.length - 1];
    const depositDate = new Date(request.depositDate);

    const metaData = season.license.additionalMetadata;

    const withAdminCSJ =
        !!metaData &&
        !!metaData.administrativeRequirementsAdditions &&
        !!metaData.administrativeRequirementsAdditions.ownsLicenseCSJNationale;

    const withDiversCertificates =
        !!metaData &&
        !!metaData.administrativeRequirementsAdditions &&
        !!metaData.administrativeRequirementsAdditions.diversAllowedCertificate;

    const withStdOption =
        !!metaData &&
        !!metaData.wizardInitialStepOptionAccessoryView &&
        (metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionPlongee' ||
            metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionSpecies' ||
            metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionSeiche' ||
            metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionNbDrague');

    const withDivers =
        proxy &&
        (proxy.option === 'plongee' ||
            (!!metaData &&
                !!metaData.wizardInitialStepOptionAccessoryView &&
                metaData.wizardInitialStepOptionAccessoryView.type === 'diverRows'));

    const withZones = !!season.license.zones && season.license.zones.length > 0;
    const seasonName =
        seasonality === 'annual' ? season.baseYear.toString() : `${season.baseYear - 1}-${season.baseYear}`;

    let requesterName;
    if (company) {
        requesterName = `${company.type}${company.type ? ' ' : ''}${company.name}`;
    } else {
        requesterName = `${user.firstName}${user.firstName ? ' ' : ''}${user.lastName}`;
    }

    const data = {
        requesterName,

        email: user.email,
        boatName: boat.name,
        boatRegistration: `${request.boat.registrationDistrictCode} ${request.boat.registration}`,

        depositDate: formatLocaleDate(depositDate, 'dd/MM/yyyy'),
        depositTime: formatLocaleDate(depositDate, 'kk:mm'),
        seasonName,

        licenseName: request.license.name,
        licenseRequestNature: getRequestTypeLabel(request, false).toLowerCase(),
        pricings: pricingsDetail(request),

        requestBeginDate: lastSeasonPart
            ? formatLocaleDate(new Date(lastSeasonPart.requestBeginDate), 'dd/MM/yyyy')
            : '',
        requestEndDate: lastSeasonPart ? formatLocaleDate(new Date(lastSeasonPart.requestEndDate), 'dd/MM/yyyy') : '',

        withAdminCSJ,
        withDiversCertificates,

        withStdOption,
        option: withStdOption ? request.additionalMetadata.option : '',
        withDivers,
        divers: request.divers || [],
        withZones,
        zones: (request.targetedZones || []).map((zoneId) => {
            const zone = season.license.zones.find((z) => z.uniqueId === zoneId);
            return zone ? zone.name : '?';
        })
    };

    return api.sendLicenseRequestReceiptPEP(data);
}
