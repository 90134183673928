import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { getRequestStatusLabel, getRequestTypeLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';
import { useCurrentUserQuery, useGetUserPAPRequests, useGetAdministrativeDivisionsList } from '@SUPPORT/queries';

import { Label } from '@COMPONENTS/licensee/common/Label';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { TextInput } from '@COMPONENTS/licensee/common/TextInput';
import { Main, Section, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import {
    LargeContainerTitle,
    LargeContainerSubTitle,
    LargeContainerContent
} from '@COMPONENTS/licensee/common/LargeContainer';

const ExtractName = styled.h4`
    position: relative;
    display: block;
    flex-shrink: 0;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: var(--font-family);
    color: black;

    & > span {
        position: relative;
        display: block;
        flex-shrink: 0;
        font-size: 1.4rem;
        font-weight: 500;
        font-family: var(--font-family);
        color: var(--light-gray);
    }
`;

const LicenceInfo = styled.div`
    position: relative;
    margin-bottom: calc(var(--standard-margin) * 0.8);
`;

const Form = styled.form`
    position: relative;
    left: 0.1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--form-elements-width), 1fr));
    grid-column-gap: var(--standard-margin);
    margin-top: 0.8rem;
    padding-left: calc(var(--standard-margin) * 0.8);
    padding-right: calc(var(--standard-margin) * 0.8);
    border-left: solid 0.1rem var(--dark-blue);

    & > div {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.7rem;
    }

    ${Label} {
        padding-left: 0.7rem;
    }
`;

export function StatusFinal() {
    const { id: seasonId } = useParams();
    const { data: user } = useCurrentUserQuery();
    const { data: seasons = [] } = useGetUserPAPRequests(user ? user.id : null);

    const season = seasons ? seasons.find((season) => season.uniqueId === seasonId) : null;

    return (
        <Main>
            <TopBackButton />
            <Section>
                <Intro>
                    <Title>Statut de vos timbre(s) Pêche à pied</Title>
                    {season && (
                        <Fragment>
                            <SubTitle>
                                Campagne {season.referenceYear - 1}-{season.referenceYear}
                            </SubTitle>
                            <RequestsBlock season={season} status="kPSLicenseRequestGlobalStatusInitial" />
                            <RequestsBlock season={season} status="kPSLicenseRequestGlobalStatusFrozen" />
                            <RequestsBlock season={season} status="kPSLicenseRequestGlobalStatusSuspended" />
                            <RequestsBlock season={season} status="kPSLicenseRequestGlobalStatusReserved" />
                            <RequestsBlock season={season} status="kPSLicenseRequestGlobalStatusAllowed" />
                            <RequestsBlock season={season} status="kPSLicenseRequestGlobalStatusRefused" />
                            <RequestsBlock season={season} status="kPSLicenseRequestGlobalStatusCancelled" />
                        </Fragment>
                    )}
                </Intro>
            </Section>
        </Main>
    );
}

function RequestsBlock({ season, status }) {
    const { data: divisions = [] } = useGetAdministrativeDivisionsList();

    if (!season) {
        return null;
    }

    const now = new Date();
    const request = season.licenseRequest;
    const extracts = request.extractRequests.filter((extract) => {
        const start = new Date(season.referenceYear - 1, 0, 1); // 01/01/XXXX
        const end = new Date(season.referenceYear - 1, 3, 15); // 15/04/XXXX
        const extractStatus = now >= start && now < end ? 'kPSLicenseRequestGlobalStatusInitial' : extract.globalStatus;
        return extractStatus === status;
    });
    if (extracts.length === 0) {
        return null;
    }

    const content = [];
    let currentDiv = '';
    extracts.forEach((extract) => {
        const fullExtract = season.extracts.find((ex) => ex.uniqueId === extract.targetedExtract);
        const division = divisions.find((div) => div.administrativeDivisionPath === fullExtract.administrativeDivision);
        if (division && division.name !== currentDiv) {
            currentDiv = division.name;
            content.push(
                <LargeContainerSubTitle key={division.zipCode}>
                    {division.name} {division.zipCode}
                </LargeContainerSubTitle>
            );
        }

        content.push(<ExtractBlock name={fullExtract.name} extract={extract} key={fullExtract.uniqueId} />);
    });

    return (
        <Fragment>
            <LargeContainerTitle className={getRequestStatusColorCode(status)}>
                {getRequestStatusLabel(status, true, true)}
            </LargeContainerTitle>
            <LargeContainerContent>{content}</LargeContainerContent>
        </Fragment>
    );
}

function ExtractBlock({ name, extract }) {
    return (
        <LicenceInfo>
            <ExtractName>{name}</ExtractName>
            <Form>
                <div>
                    <Label>Nature :</Label>
                    <TextInput value={getRequestTypeLabel(extract)} readOnly />
                </div>
                <div>
                    <Label>Référence du paiement 1 :</Label>
                    <TextInput value="" readOnly />
                </div>
                <div>
                    <Label>Référence du paiement 2 :</Label>
                    <TextInput value="" readOnly />
                </div>
            </Form>
        </LicenceInfo>
    );
}
