import styled from 'styled-components';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import { useAlertDialog, useDismissAlert } from '@STORES/licensee';

import { useDeletePEPRequest } from '@SUPPORT/queries';
import { loadTemporaryPEPRequest, clearTemporaryPEPRequest } from '@SUPPORT/utils';

import { Button } from '@COMPONENTS/licensee/common/Button';

export function CancelButton() {
    const history = useHistory();
    const showAlert = useAlertDialog();
    const dismissAlert = useDismissAlert();
    const deleteMutation = useDeletePEPRequest();

    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);

    const cancel = useCallback(() => {
        showAlert({
            title: 'Annuler la demande',
            okLabel: "Confirmer l'annulation",
            okIsPrimary: true,
            dismissLabel: 'Maintenir la demande',
            messages: [
                `Je souhaite annuler et supprimer cette demande pour la campagne à venir Pêche Embarqué.`,
                `Je pourrai si besoin déposer une nouvelle demande.`
            ],

            okCb: () => {
                if (currentRequest && currentRequest.uniqueId) {
                    deleteMutation.mutate(
                        { id: currentRequest.uniqueId },
                        {
                            onSuccess: () => {
                                clearTemporaryPEPRequest();
                                history.replace('/');
                                dismissAlert();
                            }
                        }
                    );
                } else {
                    clearTemporaryPEPRequest();
                    history.replace('/');
                    dismissAlert();
                }
            }
        });
    }, [history, showAlert, dismissAlert, currentRequest, deleteMutation]);

    return <Button onClick={cancel}>Annuler cette demande</Button>;
}

export const NextButton = styled(Button)`
    margin-left: auto;
`;

export function pricingsDetail(req) {
    return (req.pricings || [])
        .filter(Boolean)
        .map((p) => `${p}€`)
        .join(' + ');
}
