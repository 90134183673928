import React, { Fragment, useState, useMemo, useCallback, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';

import { getOptionStatusLabel } from '@STORES/common/pescalice';

import { useGetCurrentPEPProxy } from '@SUPPORT/queries';
import { loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';

import { CancelButton, NextButton } from './common';
import { Label } from '@COMPONENTS/licensee/common/Label';
import { TextInput } from '@COMPONENTS/licensee/common/TextInput';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Section, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import {
    Check,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

const Form = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1rem var(--standard-margin);
    gap: 0.5rem;

    & > div {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        margin-bottom: 0.6rem;
    }

    & > div > div {
        position: relative;
        display: flex;
        gap: 0.7rem;
    }

    & > div > div input {
        width: calc(var(--form-elements-width) * 0.8);
    }

    & > div > div input:last-of-type {
        width: calc(var(--form-elements-width) * 0.4);
    }

    ${Label} {
        padding-left: 0.7rem;
    }
`;

export function RequestOptions() {
    const history = useHistory();
    const { seasonality } = useParams();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);
    const currentProxy = useGetCurrentPEPProxy(currentRequest);
    const currentSeason = currentProxy
        ? currentProxy.seasons.find((season) => season.license.uniqueId === currentRequest.license)
        : null;

    const [selectedOption, selectOption] = useState();
    const [divers, dispatch] = useReducer(diversReducer, initialDiversState);
    const withDivers =
        (selectedOption === 'plongee' && currentSeason && currentSeason.baseYear < 2023) ||
        (currentProxy &&
            currentProxy.seasons[0].license.additionalMetadata &&
            currentProxy.seasons[0].license.additionalMetadata.wizardInitialStepOptionAccessoryView &&
            currentProxy.seasons[0].license.additionalMetadata.wizardInitialStepOptionAccessoryView.type ===
                'diverRows');

    useEffect(() => {
        if (currentRequest && currentRequest.additionalMetadata && currentRequest.additionalMetadata.option) {
            selectOption(currentRequest.additionalMetadata.option);
        } else if (
            currentProxy &&
            currentProxy.seasons[0].license.additionalMetadata.wizardInitialStepOptionAccessoryView
        ) {
            selectOption(currentProxy.seasons[0].license.additionalMetadata.defaultOption);
        }

        if (currentRequest && currentRequest.divers) {
            dispatch({ type: 'init', divers: currentRequest.divers });
        }
    }, [currentRequest, currentProxy]);

    const onBack = useCallback(() => {
        if (currentRequest) {
            delete currentRequest.additionalMetadata;
            delete currentRequest.divers;
            saveTemporaryPEPRequest(currentRequest);
        }
    }, [currentRequest]);

    const next = useCallback(() => {
        if (!currentRequest) {
            alert('Erreur inconnue');
            history.push('/');
            return;
        }

        currentRequest.additionalMetadata = { option: selectedOption };
        if (withDivers) {
            currentRequest.divers = divers.filter(
                (diver) => diver.firstName.trim() !== '' && diver.lastName.trim() !== '' && diver.enim.trim() !== ''
            );
        }

        saveTemporaryPEPRequest(currentRequest);

        if (
            selectedOption === 'Laminaria Digitata' ||
            selectedOption === 'Laminaria Hyperborea + Laminaria Digitata' ||
            (currentProxy.seasons[0].license.zones && currentProxy.seasons[0].license.zones.length > 0)
        ) {
            history.push(`/request/pep/${seasonality}/zones`);
        } else {
            history.push(`/request/pep/${seasonality}/admin`);
        }
    }, [selectedOption, withDivers, divers, currentRequest, currentProxy, history, seasonality]);

    return (
        <Fragment>
            <Main>
                <TopBackButton onBack={onBack} />
                <Section>
                    <Intro>
                        <Title>Options pour votre demande de licences pour la pêche embarquée</Title>
                        <SubTitle>
                            Veuillez sélectionner l&apos;option que vous souhaitez pour la licence :{' '}
                            {currentProxy ? currentProxy.name : ''}
                        </SubTitle>
                    </Intro>

                    {currentProxy && (
                        <Fragment>
                            <BigButtonGrid withMargin>
                                {currentProxy.seasons[0].license.additionalMetadata.options &&
                                    currentProxy.seasons[0].license.additionalMetadata.options.map((option) => (
                                        <ItemSelectionWithCheck
                                            itemSelected={selectedOption === option}
                                            onClick={() => selectOption(option)}
                                            key={option}
                                        >
                                            <Check />
                                            <ItemSelectionTitle>{getOptionStatusLabel(option)}</ItemSelectionTitle>
                                        </ItemSelectionWithCheck>
                                    ))}
                            </BigButtonGrid>

                            {withDivers && <DiversForm divers={divers} dispatch={dispatch} />}
                        </Fragment>
                    )}
                </Section>
            </Main>
            <Footer>
                <CancelButton />
                <NextButton primary onClick={next}>
                    Suivant
                </NextButton>
            </Footer>
        </Fragment>
    );
}

function diversReducer(state, action) {
    switch (action.type) {
        case 'init': {
            const newState = [...initialDiversState];
            action.divers.forEach((diver, i) => (newState[i] = { ...diver }));
            return newState;
        }

        case 'first-name-changed': {
            const newState = [...state];
            newState[action.index].firstName = action.value;
            return newState;
        }

        case 'last-name-changed': {
            const newState = [...state];
            newState[action.index].lastName = action.value;
            return newState;
        }

        case 'enim-changed': {
            const newState = [...state];
            newState[action.index].enim = action.value;
            return newState;
        }
    }
}

const initialDiversState = [
    {
        telecapecheId: 0,
        firstName: '',
        lastName: '',
        enim: ''
    },
    {
        telecapecheId: 0,
        firstName: '',
        lastName: '',
        enim: ''
    },
    {
        telecapecheId: 0,
        firstName: '',
        lastName: '',
        enim: ''
    }
];

function DiversForm({ divers, dispatch }) {
    return (
        <Form>
            <div>
                <Label>Plongeur 1 :</Label>
                <DiverFieldSet index={0} divers={divers} dispatch={dispatch} />
            </div>
            <div>
                <Label>Plongeur 2 :</Label>
                <DiverFieldSet index={1} divers={divers} dispatch={dispatch} />
            </div>
            <div>
                <Label>Plongeur 3 :</Label>
                <DiverFieldSet index={2} divers={divers} dispatch={dispatch} />
            </div>
        </Form>
    );
}

function DiverFieldSet({ index, divers, dispatch }) {
    const disabled =
        index > 0 &&
        (divers[index - 1].firstName.trim() === '' ||
            divers[index - 1].lastName.trim() === '' ||
            divers[index - 1].enim.trim() === '');

    return (
        <div>
            <TextInput
                disabled={disabled}
                placeholder={disabled ? '' : 'Prénom'}
                value={divers[index].firstName}
                onChange={(evt) => dispatch({ type: 'first-name-changed', index, value: evt.target.value })}
            />
            <TextInput
                disabled={disabled}
                placeholder={disabled ? '' : 'Nom'}
                value={divers[index].lastName}
                onChange={(evt) => dispatch({ type: 'last-name-changed', index, value: evt.target.value })}
            />
            <TextInput
                disabled={disabled}
                placeholder={disabled ? '' : 'Nº ENIM'}
                value={divers[index].enim}
                onChange={(evt) => dispatch({ type: 'enim-changed', index, value: evt.target.value })}
            />
        </div>
    );
}
