import React from 'react';
import { useHistory } from 'react-router';

import { useCurrentUserQuery, useGetUserPAPRequests } from '@SUPPORT/queries';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, MainText } from '@COMPONENTS/licensee/common/Main';
import { ItemSelection, BigButtonGrid, ItemSelectionTitle, Chevron } from '@COMPONENTS/licensee/common/BigButton';

export function StatusSeasonSelector() {
    const { data: user } = useCurrentUserQuery();
    const { data: seasons = [] } = useGetUserPAPRequests(user ? user.id : null);

    const now = new Date();
    const lastSeasons = seasons.filter(
        (season) => season.referenceYear <= (now.getMonth() <= 4 ? now.getFullYear() : now.getFullYear() + 1)
    );

    return (
        <Main>
            <TopBackButton />
            <Intro>
                <Title>Historique de vos licences Pêche à pied</Title>
                <MainText>Sélectionnez la campagne concernée :</MainText>
            </Intro>
            <BigButtonGrid withMargin>
                {lastSeasons.map((season) => (
                    <SeasonBlock season={season} key={season.uniqueId} />
                ))}
            </BigButtonGrid>
        </Main>
    );
}

function SeasonBlock({ season }) {
    const history = useHistory();

    const count = season.licenseRequest.extractRequests.filter(
        (req) => req.globalStatus === 'kPSLicenseRequestGlobalStatusAllowed'
    ).length;

    let message;
    if (!season) {
        message = '';
    } else if (count === 0) {
        message = 'Aucun timbre';
    } else if (count === 1) {
        message = '1 timbre';
    } else if (season.referenceYear === new Date().getFullYear()) {
        message = `Vous avez actuellement ${count} timbres attribués pour la campagne en cours.`;
    } else {
        message = `Vous aviez ${count} timbres attribués.`;
    }

    return (
        <ItemSelection onClick={() => history.push(`/status/pap/season/${season.uniqueId}`)}>
            <Chevron />
            <ItemSelectionTitle>
                Campagne {season.referenceYear - 1}-{season.referenceYear}
            </ItemSelectionTitle>
            <MainText>{message}</MainText>
        </ItemSelection>
    );
}
