import { useHistory, useParams } from 'react-router';
import React, { Fragment, useState } from 'react';

import { useCurrentUserQuery, useCurrentUserCompaniesListQuery } from '@SUPPORT/queries';

import { NextButton } from '@COMPONENTS/licensee/pages/pep/common';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, MainText } from '@COMPONENTS/licensee/common/Main';
import {
    BigButtonGrid,
    ItemSelectionTitle,
    Check,
    ItemSelectionWithCheck
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

export function StatusCompanySelector() {
    const history = useHistory();

    const { data: user } = useCurrentUserQuery();
    const { data: companies = [] } = useCurrentUserCompaniesListQuery(user ? user.id : null);
    const { seasonality } = useParams();

    const [selectedCompanyId, selectCompany] = useState('');
    const next = () => {
        localStorage.setItem('pep-status-company', selectedCompanyId);
        if (seasonality) {
            history.push(`/request/pep/${seasonality}/status/boat`);
        } else {
            history.push('/status/pep/boat');
        }
    };

    return (
        <Fragment>
            <Main>
                <TopBackButton />
                <Intro>
                    <Title>Statut de vos licences Pêche Embarquée</Title>
                    <MainText>Sélectionnez l&apos;entreprise concernée :</MainText>
                </Intro>
                <BigButtonGrid withMargin>
                    {companies.map((company) => (
                        <ItemSelectionWithCheck
                            itemSelected={selectedCompanyId === company.id}
                            onClick={() => selectCompany(company.id)}
                            key={company.id}
                        >
                            <Check />
                            <ItemSelectionTitle>{company.name}</ItemSelectionTitle>
                        </ItemSelectionWithCheck>
                    ))}
                    <ItemSelectionWithCheck
                        itemSelected={selectedCompanyId === null}
                        onClick={() => selectCompany(null)}
                    >
                        <Check />
                        <ItemSelectionTitle>Sans Entreprise (en nom propre)</ItemSelectionTitle>
                    </ItemSelectionWithCheck>
                </BigButtonGrid>
            </Main>
            <Footer>
                <NextButton primary onClick={next} disabled={selectedCompanyId === ''}>
                    Suivant
                </NextButton>
            </Footer>
        </Fragment>
    );
}
