import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { useLogoutSessionQuery } from '@SUPPORT/queries';

import LogoSVG from '@GRAPHICS/licensee/logo.svg';
import LogoutSVG from '@GRAPHICS/licensee/logout.svg';

const Container = styled.header`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--header-footer-height);
    padding-right: var(--standard-margin);
    padding-left: var(--standard-margin);

    &::after {
        content: '';
        position: absolute;
        bottom: -0.8rem;
        left: 0;
        right: 0;
        height: 0.8rem;
        background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.04) 80%,
            rgba(0, 0, 0, 0.15) 100%
        );
    }
`;

const PescaliceLogo = styled(LogoSVG)`
    --logo-width: 12rem;
    width: var(--logo-width);
    height: calc(var(--logo-width) * 0.18);
    color: var(--dark-blue);
    cursor: pointer;
`;

const LogoutIcon = styled(LogoutSVG)`
    --logo-width: 2.2rem;
    width: var(--logo-width);
    height: var(--logo-width);
    color: var(--dark-blue);
    cursor: pointer;
`;

export function Header() {
    const logout = useLogoutSessionQuery();

    const history = useHistory();
    const backToHome = () => history.push('/');

    return (
        <Container>
            <PescaliceLogo onClick={backToHome} />
            <LogoutIcon onClick={logout} />
        </Container>
    );
}
