import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import BackChevron from '@GRAPHICS/licensee/chevron.svg';

const Button = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    align-self: flex-start;
    min-width: 7.5rem;
    height: calc(var(--form-elements-height) + 0.2rem);
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    margin: var(--standard-margin) 0 0 var(--standard-margin);
    font: var(--font-button);
    font-weight: 500;
    color: var(--light-blue);
    border: solid 0.2rem var(--super-light-gray);
    border-radius: 0.5rem;
    cursor: pointer;
    overflow: visible;
    background-color: transparent;
    box-sizing: border-box;
`;

const ChevronBack = styled(BackChevron)`
    --logo-width: 1.3rem;
    width: var(--logo-width);
    height: var(--logo-width);
    margin-right: 0.3rem;
    margin-left: -0.5rem;
    color: var(--light-blue);
    transform: rotate(-180deg);
`;

export function TopBackButton({ label, target, replace = false, onBack }) {
    const history = useHistory();
    const goBack = useCallback(
        (evt) => {
            evt.preventDefault();
            if (onBack) {
                onBack();
            }
            if (target) {
                if (replace) {
                    history.replace(target);
                } else {
                    history.push(target);
                }
            } else {
                history.goBack();
            }
        },
        [history, target, replace]
    );

    return (
        <Button onClick={goBack}>
            <ChevronBack />
            {label || 'Retour'}
        </Button>
    );
}
