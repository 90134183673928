import styled from 'styled-components';

export const LargeContainerTitle = styled.header`
    --gray: #e3e3e3;
    --gray-border: #dcdcdc;
    --gray-title: #727272;
    --red: #f9d3d3;
    --red-border: #f6b9b9;
    --red-title: #b20e0e;
    --ocre: #fedcaf;
    --ocre-border: #f5d7b0;
    --ocre-title: #995d10;
    --orange: #f9ebcb;
    --orange-border: #f5d7b0;
    --orange-title: #9c7f0e;
    --green: #e4f9cb;
    --green-border: #cbf29c;
    --green-title: #4d890a;
    --purple: #f3d8fc;
    --purple-border: #d9c5fe;
    --purple-title: #8848ff;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: calc(var(--standard-margin) * 0.5);
    padding: calc(var(--standard-margin) * 0.5);
    font: var(--font-title-normal);
    color: var(--titles-color);
    border-radius: var(--form-elements-border-radius) var(--form-elements-border-radius) 0 0;
    border: 1px solid #c9e5f3;
    border-bottom-style: none;
    background: var(--super-light-blue);

    &.codeGreen {
        border-color: var(--green-border);
        color: var(--green-title);
        background-color: var(--green);
    }

    &.codePurple {
        border-color: var(--purple-border);
        color: var(--purple-title);
        background-color: var(--purple);
    }

    &.codeOrange {
        border-color: var(--orange-border);
        color: var(--orange-title);
        background-color: var(--orange);
    }

    &.codeRed {
        border-color: var(--red-border);
        color: var(--red-title);
        background-color: var(--red);
    }

    &.codeGray {
        border-color: #dcdcdc;
        color: gray;
        background-color: #e3e3e3;
    }
`;

export const LargeContainerContent = styled.div`
    padding: calc(var(--standard-margin) / 1.5) calc(var(--standard-margin) / 1.5) 0.8rem
        calc(var(--standard-margin) / 1.5);
    border-radius: 0 0 var(--form-elements-border-radius) var(--form-elements-border-radius);
    border: 0.1rem solid var(--largeContainerBorderColor);
`;

export const LargeContainerSubTitle = styled.h3`
    position: relative;
    display: block;
    padding-top: 0.2rem;
    padding-bottom: 0.4rem;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: var(--font-family);
    color: var(--dark-blue);
`;
