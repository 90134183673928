import styled from 'styled-components';

import { MainText } from '@COMPONENTS/licensee/common/Main';

import CheckSVG from '@GRAPHICS/licensee/check.svg';
import ChevronSVG from '@GRAPHICS/licensee/chevron.svg';
import InfoSVG from '@GRAPHICS/licensee/info_circle.svg';

export { InfoSVG };

export const BigButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--big-button-width), 1fr));
    grid-gap: var(--standard-margin);
    grid-auto-rows: minmax(min-content, max-content);
    margin: ${({ withMargin }) => (withMargin ? 'var(--standard-margin)' : '0')};
`;

export const BigButtonGridSeparator = styled.div`
    display: block;
    grid-column: 1 / -1;
    height: 0.1rem;
    width: 100%;
    background: var(--super-light-gray);
`;

export const ItemSelectionTitle = styled.h4`
    position: relative;
    display: flex;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: var(--font-family);
    text-align: start;
    line-height: 1.1;
    color: var(--dark-blue);
`;

export const Chevron = styled(ChevronSVG)`
    --logo-height: 1.8rem;
    position: absolute;
    top: calc(50% - var(--logo-height) / 2);
    right: calc(var(--standard-margin) * 0.7);
    width: calc(var(--logo-height) * 0.8);
    height: var(--logo-height);
    color: var(--dark-blue);
`;

export const Check = styled(CheckSVG)`
    --logo-height: 2rem;
    position: absolute;
    top: 1rem;
    left: calc(var(--standard-margin) * 0.6);
    width: calc(var(--logo-height) * 0.8);
    height: var(--logo-height);
    color: var(--dark-blue);
    transition: transform 0.4s;
`;

export const Info = styled(InfoSVG)`
    --logo-height: 2.5rem;
    position: absolute;
    top: 0.8rem;
    right: calc(var(--standard-margin) * 0.6);
    width: calc(var(--logo-height) * 0.8);
    height: var(--logo-height);
    color: var(--dark-blue);
    z-index: 2;
`;

export const InsideInfo = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    padding-right: calc(var(--standard-margin) * 2.1);
    padding-left: calc(var(--standard-margin) * 0.8);
    padding-bottom: 1rem;
    cursor: pointer;
    border-radius: 0.8rem;
    border: solid 0.1rem var(--light-blue);
    overflow-y: auto;
    background: var(--super-light-blue);
    transition: transform 0.15s var(--ease-out-cubic);

    & p {
        padding-top: 10px;
        text-align: start;
        font-size: 15px;
        color: var(--dark-blue);
        transition: transform 0.35s var(--ease-out-cubic);
    }
`;

export const DateCampaign = styled(MainText)`
    align-self: flex-start;
    padding: 0.3rem 0.8rem;
    border-radius: var(--form-elements-border-radius);
    color: #1e1e1e;
    font-weight: 500;
    background: #f3f3f3;
`;

export const ItemSelection = styled.button`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    padding-top: calc(var(--standard-margin) * 0.7);
    padding-right: calc(var(--standard-margin) * 2.1);
    padding-bottom: calc(var(--standard-margin) * 0.8);
    padding-left: calc(var(--standard-margin) * 0.8);
    border-width: 0.2rem;
    border-style: solid;
    border-color: ${({ itemSelected, warning }) =>
        itemSelected ? 'var(--dark-yellow)' : warning ? '#f6b9b9' : 'var(--lighter-gray)'};
    border-radius: 0.8rem;
    overflow: hidden;
    background: ${({ itemSelected, warning }) =>
        itemSelected ? 'var(--light-yellow)' : warning ? '#f9d3d3' : 'var(--super-light-gray)'};
    box-shadow: ${({ itemSelected }) => (itemSelected ? '0 0 1rem 0.2rem var(--light-yellow)' : 'none')};
    cursor: pointer;
    transition: border 0.4s;

    & a {
        font-size: inherit;
        color: var(--light-blue);
        border-bottom: solid 0.1rem var(--light-blue);
    }

    &.disabled {
        cursor: default;
        opacity: 0.3;
    }

    &.disabled > svg {
        display: none;
    }

    &.disabled *:not(a) {
        color: gray;
    }

    ${ItemSelectionTitle} {
        color: ${({ itemSelected, warning }) =>
            itemSelected ? 'rgb(70,70,70)' : warning ? '#b20e0e' : 'var(--dark-blue)'};
    }

    ${Check} {
        transform: ${({ itemSelected }) => (itemSelected ? 'scale(1)' : 'scale(0)')};
    }

    ${InsideInfo} {
        transform: ${({ infoRequested }) => (infoRequested ? 'translateY(0)' : 'translateY(102%)')};
        & p {
            transform: ${({ infoRequested }) => (infoRequested ? 'translateY(0)' : 'translateY(2rem)')};
        }
    }
`;

export const ItemSelectionWithCheck = styled(ItemSelection)`
    padding-left: calc(var(--standard-margin) * 2);
`;
