import styled from 'styled-components';
import React, { Fragment, useCallback, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import { pricingsDetail } from './common';
import { formatLocaleDate, saveTemporaryPEPRequest, clearTemporaryPEPRequest } from '@SUPPORT/utils';
import {
    useCurrentUserQuery,
    useCurrentUserCompaniesListQuery,
    useGetUserPEPRequests,
    useGetBoatQuery,
    useGetCurrentPEPProxy
} from '@SUPPORT/queries';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import { TableTwoColumns } from '@COMPONENTS/licensee/common/TableTwoColumns';
import {
    Check,
    BigButtonGrid,
    ItemSelection,
    ItemSelectionTitle,
    Chevron,
    DateCampaign
} from '@COMPONENTS/licensee/common/BigButton';

const BoatName = styled.span`
    font-weight: bold;
`;

const ZoneDetail = styled.span`
    font-size: 14px;
    font-style: italic;
    opacity: 0.5;
`;

const RequestDate = styled.span`
    font-size: 14px;
    opacity: 0.8;
`;

const TitleWithMargin = styled(Title)`
    margin-inline-start: var(--standard-margin);
`;

const ListWithBullets = styled.ul`
    position: relative;
    margin-inline-start: calc(var(--standard-margin) * 2);
    margin-inline-end: var(--standard-margin);
    margin-top: 0.5rem;
    font-size: 1.6rem;
    text-align: left;
    font-family: var(--font-family);
    color: var(--main-text-color-lighter);

    & li {
        list-style-type: disc;
        list-style-position: outside;
        padding-bottom: 6px;
    }
`;

export function RequestMenu() {
    const history = useHistory();
    const baseYear = new Date().getFullYear();
    const { seasonality } = useParams();
    const { data: user } = useCurrentUserQuery();
    const { data: requests = [] } = useGetUserPEPRequests(user ? user.id : null, baseYear + 1, seasonality);
    const { data: companies = [] } = useCurrentUserCompaniesListQuery();

    const requiresLicenseCSJNationale = useMemo(
        () =>
            requests.some(
                (request) =>
                    request.license.additionalMetadata && request.license.additionalMetadata.requiresLicenseCSJNationale
            ),
        [requests]
    );

    const [loadingLicense, setLoadingLicense] = useState(false);
    const editRequest = useCallback(
        (request) => {
            setLoadingLicense(true);
            saveTemporaryPEPRequest(request);
            setLoadingLicense(false);
            if (request.requestAuthor.companyTelecapecheId) {
                history.push(`/request/pep/${seasonality}/company`);
            } else {
                history.push(`/request/pep/${seasonality}/boat`);
            }
        },
        [history, seasonality]
    );

    const newRequest = useCallback(() => {
        clearTemporaryPEPRequest();
        saveTemporaryPEPRequest({
            depositDate: new Date(),
            requestAuthor: {
                telecapecheId: user.id,
                companyTelecapecheId: null
            }
        });

        if (companies.length > 0) {
            history.push(`/request/pep/${seasonality}/company`);
        } else {
            history.push(`/request/pep/${seasonality}/boat`);
        }
    }, [companies, history, seasonality, user]);

    const viewRequest = useCallback(() => {
        history.push(`/request/pep/${seasonality}/status/company`);
    }, [history, seasonality]);

    const now = new Date();
    const season = seasonality === 'annual' ? now.getFullYear() + 1 : `${now.getFullYear()}-${now.getFullYear() + 1}`;

    const seasonalCreationStart = new Date(now.getFullYear(), 4, 15);
    const seasonalCreationEnd = new Date(now.getFullYear(), 5, 16);
    const allowSeasonalCreation = !loadingLicense && now >= seasonalCreationStart && now < seasonalCreationEnd;

    const annualCreationStart = new Date(now.getFullYear(), 8, 1);
    const annualCreationEnd = new Date(now.getFullYear(), 9, 1);
    const allowAnnualCreation = !loadingLicense && now >= annualCreationStart && now < annualCreationEnd;

    // AH: force that to true to enable request deposit *any time* of the year
    const allowCreation =
        seasonality === 'annual' ? allowAnnualCreation : seasonality === 'seasonal' ? allowSeasonalCreation : false;

    const seasonalViewStart = new Date(now.getFullYear(), 5, 16);
    const seasonalViewEnd = new Date(now.getFullYear(), 8, 1);
    const allowSeasonalView = now >= seasonalViewStart && now < seasonalViewEnd;

    const annualViewStart = new Date(now.getFullYear(), 9, 1);
    const annualViewEnd = new Date(now.getFullYear(), 11, 31);
    const allowAnnualView = now >= annualViewStart && now < annualViewEnd;

    const allowView =
        seasonality === 'annual' ? allowAnnualView : seasonality === 'seasonal' ? allowSeasonalView : false;

    return (
        <Main>
            <TopBackButton />
            <Intro>
                {seasonality === 'annual' ? (
                    <Fragment>
                        <Title>
                            Vos demandes de licences pour la pêche embarquée annuelle, pour la campagne à venir
                        </Title>
                        <SubTitle>Campagne Pêche embarquée annuelle {season}</SubTitle>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Title>
                            Vos demandes de licences pour la pêche embarquée coquillage et poulpe, pour la campagne à
                            venir
                        </Title>
                        <SubTitle>Campagne Pêche embarquée coquillage et poulpe {season}</SubTitle>
                    </Fragment>
                )}
            </Intro>
            <BigButtonGrid withMargin>
                <ItemSelection
                    onClick={allowCreation ? newRequest : null}
                    className={!allowCreation ? 'disabled' : null}
                >
                    <Chevron />
                    <ItemSelectionTitle>Déposer une nouvelle demande de licence</ItemSelectionTitle>
                    {seasonality === 'annual' ? (
                        <DateCampaign>Du 1er au 30 septembre</DateCampaign>
                    ) : (
                        <DateCampaign>Du 15 mai au 15 juin</DateCampaign>
                    )}
                </ItemSelection>

                <ItemSelection onClick={allowView ? viewRequest : null} className={!allowView ? 'disabled' : null}>
                    <Chevron />
                    <ItemSelectionTitle>Visualiser l&apos;état du traitement de mes demandes</ItemSelectionTitle>
                    {seasonality === 'annual' ? (
                        <DateCampaign>A partir du 1er octobre</DateCampaign>
                    ) : (
                        <DateCampaign>A partir du 16 juin</DateCampaign>
                    )}
                </ItemSelection>
            </BigButtonGrid>

            <BigButtonGrid withMargin>
                {requests.map((req) => (
                    <RequestButton
                        request={req}
                        onClick={allowCreation ? () => editRequest(req) : null}
                        disabled={loadingLicense || !allowCreation}
                        key={req.uniqueId}
                    />
                ))}
            </BigButtonGrid>

            {requests.length > 0 && (
                <Intro>
                    <Title>
                        Résumé des paiements à fournir par chèque, ou par prélèvement (si vous avez rempli vos
                        coordonées bancaires)
                    </Title>

                    <TableTwoColumns>
                        <tbody>
                            {requests.map((req) => (
                                <tr key={req.uniqueId}>
                                    <td>{req.license.name}</td>
                                    <td>{pricingsDetail(req)}</td>
                                </tr>
                            ))}
                            {requiresLicenseCSJNationale && (
                                <tr>
                                    <td>Licence CSJ Nationale</td>
                                    <td>35€</td>
                                </tr>
                            )}
                            <tr>
                                <td>Total</td>
                                <td className="bold">
                                    {requests.reduce(
                                        (p, req) => p + req.pricings.reduce((t, p) => t + p, 0),
                                        requiresLicenseCSJNationale ? 35 : 0
                                    )}
                                    €
                                </td>
                            </tr>
                        </tbody>
                    </TableTwoColumns>
                    {/* <SubTitle>Blah blah administratif.</SubTitle> */}
                </Intro>
            )}

            <TitleWithMargin>Rappels</TitleWithMargin>
            {seasonality === 'annual' ? (
                <ListWithBullets>
                    <li>
                        La dépose et la modification de demandes de licences Pêche embarquée annuelle peut se faire du
                        1er au 30 septembre.
                    </li>
                    <li>
                        L&apos;état du traitement de votre demande sera visualisable, sans modification, dès le 1er
                        octobre.
                    </li>
                    <li>
                        Enfin, à partir du 15 décembre, la statut de votre demande sera visible sur cette interface.
                        Seule la notification du CRPMEM fait foi pour l&apos;attribution de votre demande. Si votre
                        licence est en attente ou refusée, vous recevrez un courrier précisant le motif, les délais de
                        régularisation et les voies de recours.
                    </li>
                </ListWithBullets>
            ) : (
                <ListWithBullets>
                    <li>
                        La dépose et la modification de demandes de licences Pêche embarquée Coquillage et Poulpe peut
                        se faire du 15 mai au 15 juin.
                    </li>
                    <li>
                        L&apos;état du traitement de votre demande sera visualisable, sans modification, dès le 16 juin.
                    </li>
                    <li>
                        Enfin, à partir du 15 septembre, la statut de votre demande sera visible sur cette interface.
                        Seule la notification du CRPMEM fait foi pour l&apos;attribution de votre demande. Si votre
                        licence est en attente ou refusée, vous recevrez un courrier précisant le motif, les délais de
                        régularisation et les voies de recours.
                    </li>
                </ListWithBullets>
            )}
        </Main>
    );
}

function RequestButton({ request, onClick, disabled }) {
    const { data: boat } = useGetBoatQuery(request.boat.telecapecheId);

    const currentProxy = useGetCurrentPEPProxy(request);
    const license = useMemo(() => (currentProxy ? currentProxy.seasons[0].license : null), [currentProxy]);

    let zoneDetail = null;
    if (request.targetedZones && request.targetedZones.length > 0 && !!license) {
        const zones = request.targetedZones.map((zid) => {
            const zone = license.zones.find((z) => z.uniqueId === zid);
            return zone ? zone.name : '?';
        });
        zoneDetail = zones.join(', ');
    }

    return (
        <ItemSelection onClick={onClick} className={disabled ? 'disabled' : null}>
            <Check />
            <ItemSelectionTitle>{request.license.name}</ItemSelectionTitle>
            <MainText>
                <BoatName>
                    {boat ? `${boat.name} (${boat.registrationDistrictCode} ${boat.registration})` : ''}
                </BoatName>
                <br />
                {zoneDetail && (
                    <Fragment>
                        <ZoneDetail>{zoneDetail}</ZoneDetail>
                        <br />
                    </Fragment>
                )}
                <RequestDate>{formatLocaleDate(request.depositDate, 'dd/MM/yyyy')}</RequestDate>
            </MainText>
        </ItemSelection>
    );
}
