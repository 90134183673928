import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { getRequestStatusLabel, getRequestTypeLabel, getRequestStatusColorCode } from '@STORES/common/pescalice';
import { useCurrentUserQuery } from '@SUPPORT/queries';

import { Label } from '@COMPONENTS/licensee/common/Label';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { TextInput } from '@COMPONENTS/licensee/common/TextInput';
import { Main, Section, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import { LargeContainerTitle, LargeContainerContent } from '@COMPONENTS/licensee/common/LargeContainer';
import { useGetBoatQuery, useGetCompanyQuery, useGetUserPEPLicenses } from '../../../support/queries';

const ExtractName = styled.h4`
    position: relative;
    display: block;
    flex-shrink: 0;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: var(--font-family);
    color: black;

    & > span {
        position: relative;
        display: block;
        flex-shrink: 0;
        font-size: 1.4rem;
        font-weight: 500;
        font-family: var(--font-family);
        color: var(--light-gray);
    }
`;

const LicenceInfo = styled.div`
    position: relative;
    margin-bottom: calc(var(--standard-margin) * 0.8);
`;

const Form = styled.form`
    position: relative;
    left: 0.1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--form-elements-width), 1fr));
    grid-column-gap: var(--standard-margin);
    margin-top: 0.8rem;
    padding-left: calc(var(--standard-margin) * 0.8);
    padding-right: calc(var(--standard-margin) * 0.8);
    border-left: solid 0.1rem var(--dark-blue);

    & > div {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.7rem;
    }

    ${Label} {
        padding-left: 0.7rem;
    }
`;

export function StatusFinalPEP() {
    const { seasonality: seasonalityForUpcomingCampaign } = useParams();

    const { data: user } = useCurrentUserQuery();
    const { data: allRequests = [] } = useGetUserPEPLicenses(user ? user.id : null);

    const companyId = JSON.parse(localStorage.getItem('pep-status-company'));
    const { data: company } = useGetCompanyQuery(companyId);
    const companyLabel = company ? company.name : '';

    const boatId = JSON.parse(localStorage.getItem('pep-status-boat'));
    const { data: boat } = useGetBoatQuery(boatId);
    const boatLabel = boat ? `${boat.name} (${boat.registrationDistrictCode}${boat.registration})` : '';

    const companyRequests = allRequests.find((info) => info.companyTelecapecheId === companyId) || { boats: {} };

    const now = new Date();
    const baseYear = now.getFullYear();

    let requests = companyRequests.boats[boatId] || {};

    // Force status to kPSLicenseRequestGlobalStatusInitial during magic period
    if (seasonalityForUpcomingCampaign) {
        let start, end;
        if (seasonalityForUpcomingCampaign === 'seasonal') {
            start = new Date(now.getFullYear(), 5, 16);
            end = new Date(now.getFullYear(), 8, 1);
        } else if (seasonalityForUpcomingCampaign === 'annual') {
            start = new Date(now.getFullYear(), 9, 1);
            end = new Date(now.getFullYear(), 11, 15);
        }

        if (now >= start && now < end) {
            requests.kPSLicenseRequestGlobalStatusInitial = [
                ...(requests.kPSLicenseRequestGlobalStatusInitial || []),
                ...(requests.kPSLicenseRequestGlobalStatusFrozen || []),
                ...(requests.kPSLicenseRequestGlobalStatusSuspended || []),
                ...(requests.kPSLicenseRequestGlobalStatusReserved || []),
                ...(requests.kPSLicenseRequestGlobalStatusAllowed || []),
                ...(requests.kPSLicenseRequestGlobalStatusRefused || []),
                ...(requests.kPSLicenseRequestGlobalStatusCancelled || [])
            ];
            requests.kPSLicenseRequestGlobalStatusFrozen = [];
            requests.kPSLicenseRequestGlobalStatusSuspended = [];
            requests.kPSLicenseRequestGlobalStatusReserved = [];
            requests.kPSLicenseRequestGlobalStatusAllowed = [];
            requests.kPSLicenseRequestGlobalStatusRefused = [];
            requests.kPSLicenseRequestGlobalStatusCancelled = [];
        }
    }

    return (
        <Main>
            <TopBackButton />
            <Section>
                <Intro>
                    <Title>Campagnes de pêche embarquée</Title>
                    <Fragment>
                        <SubTitle>
                            {seasonalityForUpcomingCampaign === 'annual'
                                ? `Annuelles ${baseYear}`
                                : seasonalityForUpcomingCampaign === 'seasonal'
                                ? `Saisonnières ${baseYear} - ${baseYear + 1}`
                                : `Annuelles ${baseYear} et saisonnières ${baseYear} - ${baseYear + 1}`}
                        </SubTitle>
                        {company && <SubTitle>Entreprise: {companyLabel}</SubTitle>}
                        <SubTitle>Navire: {boatLabel}</SubTitle>

                        <StatusBlock requests={requests} status="kPSLicenseRequestGlobalStatusInitial" />
                        <StatusBlock requests={requests} status="kPSLicenseRequestGlobalStatusFrozen" />
                        <StatusBlock requests={requests} status="kPSLicenseRequestGlobalStatusSuspended" />
                        <StatusBlock requests={requests} status="kPSLicenseRequestGlobalStatusReserved" />
                        <StatusBlock requests={requests} status="kPSLicenseRequestGlobalStatusAllowed" />
                        <StatusBlock requests={requests} status="kPSLicenseRequestGlobalStatusRefused" />
                        <StatusBlock requests={requests} status="kPSLicenseRequestGlobalStatusCancelled" />
                    </Fragment>
                </Intro>
            </Section>
        </Main>
    );
}

function StatusBlock({ requests, status }) {
    const { seasonality: seasonalityForUpcomingCampaign } = useParams();

    if (!requests) {
        requests = {};
    }

    let filteredRequests = requests[status];
    if (!filteredRequests) {
        filteredRequests = [];
    }

    const now = new Date();

    filteredRequests = filteredRequests.filter((request) => {
        // Campaign à venir
        if (seasonalityForUpcomingCampaign) {
            if (request.license.season.seasonality === seasonalityForUpcomingCampaign) {
                return request.license.season.baseYear - 1 === now.getFullYear();
            } else {
                return false;
            }
        }

        // Campaign en cours
        if (request.license.season.seasonality === 'annual') {
            return request.license.season.baseYear === now.getFullYear();
        } else if (request.license.season.seasonality === 'seasonal') {
            const start = new Date(request.license.season.baseYear - 1, 8, 1); // 1er septembre
            const end = new Date(request.license.season.baseYear, 7, 31); // 31 août
            return now >= start && now <= end;
        }

        return false;
    });

    return (
        <Fragment>
            <LargeContainerTitle className={getRequestStatusColorCode(status)}>
                {getRequestStatusLabel(status, true, true)}
            </LargeContainerTitle>
            <LargeContainerContent>
                {filteredRequests.length === 0 ? (
                    <EmptyStatus status={status} />
                ) : (
                    filteredRequests.map((request) => <RequestBlock request={request} key={request.uniqueId} />)
                )}
            </LargeContainerContent>
        </Fragment>
    );
}

function EmptyStatus({ status }) {
    return `0 ${getRequestStatusLabel(status).toLowerCase()}`;
}

function RequestBlock({ request }) {
    return (
        <LicenceInfo>
            <ExtractName>{request.license.name}</ExtractName>
            <Form>
                <div>
                    <Label>Nature :</Label>
                    <TextInput value={getRequestTypeLabel(request, false)} readOnly />
                </div>
            </Form>
        </LicenceInfo>
    );
}
