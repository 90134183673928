import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import groupBy from 'lodash.groupby';
import { useHistory, useParams } from 'react-router';

import { useGetCurrentPEPProxy, useGetPEPRequestHint } from '@SUPPORT/queries';
import { loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';

import { CancelButton, NextButton } from './common';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Section, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import {
    Check,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';
import { LargeContainerContent, LargeContainerTitle } from '@COMPONENTS/licensee/common/LargeContainer';

const Place = styled(MainText)`
    margin-bottom: 0.8rem;
`;

const HighlightedSubTitle = styled(SubTitle)`
    color: var(--orange);
`;

export function RequestZones() {
    const history = useHistory();
    const { seasonality } = useParams();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);
    const currentProxy = useGetCurrentPEPProxy(currentRequest);

    const license = currentProxy ? currentProxy.seasons[0].license : null;
    const metadata = license ? license.additionalMetadata : null;
    const comment = metadata && metadata.zone && metadata.zone.comment ? metadata.zone.comment : null;
    const maxSelectable =
        metadata && metadata.zone && metadata.zone.maxSelectableZones ? metadata.zone.maxSelectableZones : -1;

    const [selection, setSelection] = useState([]);

    const { data: hint, isLoading: hintIsLoading } = useGetPEPRequestHint(
        currentRequest ? currentRequest.license : '',
        currentRequest && currentRequest.boat ? currentRequest.boat.telecapecheId : '',
        currentRequest && currentRequest.requestAuthor ? currentRequest.requestAuthor.telecapecheId : '',
        currentRequest && currentRequest.requestAuthor ? currentRequest.requestAuthor.companyTelecapecheId : ''
    );
    const hasProposedZones = !hintIsLoading && hint && hint.proposedZones && hint.proposedZones.length > 0;
    useEffect(() => {
        if (hasProposedZones) {
            setSelection(hint.proposedZones);
        } else if (currentRequest) {
            setSelection(currentRequest.targetedZones || []);
        }
    }, [hasProposedZones, hint, currentRequest]);

    const selectZone = useCallback(
        (id) => {
            if (selection.includes(id)) {
                selection.splice(selection.indexOf(id), 1);
                setSelection([...selection]);
            } else if (maxSelectable === -1 || selection.length < maxSelectable) {
                setSelection([...selection, id]);
            } else {
                selection.splice(-1, 1);
                setSelection([...selection, id]);
            }
        },
        [selection, maxSelectable]
    );

    const onBack = useCallback(() => {
        if (currentRequest) {
            delete currentRequest.targetedZones;
            delete currentRequest.obtainedZones;
            saveTemporaryPEPRequest(currentRequest);
        }
    }, []);

    const next = useCallback(() => {
        if (!currentRequest) {
            alert('Erreur inconnue');
            history.push('/');
            return;
        }

        currentRequest.targetedZones = [...selection];
        currentRequest.obtainedZones = [];
        saveTemporaryPEPRequest(currentRequest);
        history.push(`/request/pep/${seasonality}/admin`);
    }, [selection, currentRequest, history, seasonality]);

    const zones = useMemo(() => {
        if (!currentProxy) {
            return {};
        }

        const z = groupBy(currentProxy.seasons[0].license.zones, (z) =>
            z.parentZoneName === '' ? null : z.parentZoneName
        );
        Object.keys(z).forEach(
            (group) =>
                (z[group] = z[group].slice().sort((z1, z2) => z1.name.localeCompare(z2.name, 'fr', { numeric: true })))
        );
        return z;
    }, [currentProxy]);

    return (
        <Fragment>
            <Main>
                <TopBackButton onBack={onBack} />
                <Section>
                    <Intro>
                        <Title>Zones</Title>
                        {hasProposedZones ? (
                            <HighlightedSubTitle>
                                Si vous souhaitez faire une demande de zone/secteur supplémentaire pour la licence{' '}
                                {currentProxy ? currentProxy.name : ''}, vous devez adresser un courrier au CRPMEM /
                                CDPM.
                            </HighlightedSubTitle>
                        ) : !hintIsLoading ? (
                            <SubTitle>
                                Veuillez sélectionner les zones que vous souhaitez obtenir pour la licence :{' '}
                                {currentProxy ? currentProxy.name : ''}
                            </SubTitle>
                        ) : null}
                        {comment && <SubTitle>{comment}</SubTitle>}

                        {Object.keys(zones)
                            .sort((k1, k2) => k1.localeCompare(k2, 'fr', { numeric: true }))
                            .map((group) => (
                                <Fragment key={group}>
                                    <LargeContainerTitle>{group === 'null' ? ' ' : group}</LargeContainerTitle>
                                    <LargeContainerContent>
                                        <BigButtonGrid withMargin>
                                            {zones[group].map((zone) => (
                                                <ItemSelectionWithCheck
                                                    key={zone.uniqueId}
                                                    itemSelected={selection.includes(zone.uniqueId)}
                                                    onClick={hasProposedZones ? null : () => selectZone(zone.uniqueId)}
                                                    className={hasProposedZones ? 'disabled' : ''}
                                                >
                                                    <Check />
                                                    <ItemSelectionTitle>{zone.name}</ItemSelectionTitle>
                                                    <Place>{zone.information}</Place>
                                                </ItemSelectionWithCheck>
                                            ))}
                                        </BigButtonGrid>
                                    </LargeContainerContent>
                                </Fragment>
                            ))}
                    </Intro>
                </Section>
            </Main>
            <Footer>
                <CancelButton />
                <NextButton primary onClick={next}>
                    Suivant
                </NextButton>
            </Footer>
        </Fragment>
    );
}
