import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { useCurrentUserQuery } from '@SUPPORT/queries';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import { ItemSelection, ItemSelectionTitle, BigButtonGrid, Chevron } from '@COMPONENTS/licensee/common/BigButton';

export function RequestSelector() {
    const history = useHistory();

    const { data: user } = useCurrentUserQuery();

    const allowPAP = !!user && !!user.tags && user.tags.includes('psc_pap');
    const selectPAP = () => history.push('/request/pap/upcoming');

    const allowPEP = !!user && !!user.tags && user.tags.includes('psc'); // && user.tags.includes('pep');
    const selectPEP = (seasonality) => () => {
        history.push(`/request/pep/${seasonality}`);
    };

    // FIXME: MOVE THIS FUCKING LOGIC DOWN A LEVEL ?

    // const baseYear = new Date().getFullYear();
    // const { data: lastPEPSeasonalReqs, isLoading: isLoadingPEPSeasonal } = useGetUserPEPRequests(
    //     user ? user.id : null,
    //     baseYear + 1,
    //     'seasonal'
    // );
    // const { data: lastPEPAnnualReqs, isLoading: isLoadingPEPAnnual } = useGetUserPEPRequests(
    //     user ? user.id : null,
    //     baseYear + 1,
    //     'annual'
    // );

    // const seasonalStartDate = new Date(now.getFullYear(), 4, 15);
    // const seasonalEndDate = new Date(now.getFullYear(), 5, 15);
    // const allowPEPSeasonal = (now >= seasonalStartDate && now <= seasonalEndDate) || isOnTest;
    // const labelPEPSeasonal =
    //     !user || isLoadingPEPSeasonal
    //         ? ''
    //         : lastPEPSeasonalReqs.length === 1
    //         ? '1 demande déposée.'
    //         : lastPEPSeasonalReqs.length > 1
    //         ? `${lastPEPSeasonalReqs.length} demandes déposées`
    //         : '';

    // const allowPEPAnnual = now.getMonth() === 8 || isOnTest;
    // const labelPEPAnnual =
    //     !user || isLoadingPEPAnnual
    //         ? ''
    //         : lastPEPAnnualReqs.length === 1
    //         ? '1 demande déposée.'
    //         : lastPEPAnnualReqs.length > 1
    //         ? `${lastPEPAnnualReqs.length} demandes déposées`
    //         : '';

    // const selectPEP = useCallback(
    //     (seasonality) => {
    //         return () => {
    //             if (user && !isValidEmail(user.email)) {
    //                 showEmailAlert();
    //             } else {
    //                 history.push(`/request/pep/${seasonality}`);
    //             }
    //         };
    //     },
    //     [user, showEmailAlert, history]
    // );

    return (
        <Main>
            <TopBackButton />

            <Intro>
                <Title>Campagnes à venir</Title>
                <SubTitle>
                    Deposez ou modifiez une demande pour la campagne à venir, en pêche à pied ou bien en pêche
                    embarquée&nbsp;:
                </SubTitle>
            </Intro>
            <BigButtonGrid withMargin>
                <ItemSelection className={classNames({ disabled: !allowPAP })} onClick={allowPAP ? selectPAP : null}>
                    <Chevron />
                    <ItemSelectionTitle>Pêche à pied</ItemSelectionTitle>
                </ItemSelection>

                <ItemSelection
                    className={classNames({ disabled: !allowPEP })}
                    onClick={allowPEP ? selectPEP('seasonal') : null}
                >
                    <Chevron />
                    <ItemSelectionTitle>
                        Pêche embarquée - Campagne saisonnière (coquillages & poulpe)
                    </ItemSelectionTitle>
                    {/* <MainText>{labelPEPSeasonal}</MainText> */}
                </ItemSelection>

                <ItemSelection
                    className={classNames({ disabled: !allowPEP })}
                    onClick={allowPEP ? selectPEP('annual') : null}
                >
                    <Chevron />
                    <ItemSelectionTitle>Pêche embarquée annuelle</ItemSelectionTitle>
                    {/* <MainText>{labelPEPAnnual}</MainText> */}
                </ItemSelection>
            </BigButtonGrid>
            {/* <Intro>
                <SubTitle>
                    Note: Pour déposer une demande de licence en dehors des périodes de demandes, contactez votre CDPMEM
                    (ou le CRPMEM pour les demandeurs hors Bretagne).
                </SubTitle>
            </Intro> */}
        </Main>
    );
}
