import styled from 'styled-components';

export const Main = styled.main`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    z-index: 0;
    width: 100%;
    overflow-y: auto;
    background: var(--main-background);
`;

export const Intro = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    grid-column: 1 / -1;
    margin: var(--standard-margin);
    font-size: 1.8rem;
    font-weight: 400;
    font-family: var(--font-family);
    color: var(--main-text-color-lighter);

    @media (max-width: 812px) {
        & {
            margin: var(--standard-margin) var(--standard-margin) 0 var(--standard-margin);
        }
    }
`;

export const Section = styled.section`
    position: relative;
    flex: 1;
    padding-bottom: calc(var(--standard-margin) * 2);
`;

export const Title = styled.h3`
    position: relative;
    display: block;
    flex-shrink: 0;
    margin: 0.8rem 0;
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--light-blue);
`;

export const SubTitle = styled.h4`
    position: relative;
    display: block;
    flex-shrink: 0;
    margin-bottom: 0.8rem;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: var(--font-family);
    color: var(--light-gray);

    & a {
        font-size: 1.5rem;
        font-weight: 500;
    }
`;

export const MainText = styled.p`
    position: relative;
    font-size: 1.6rem;
    margin-top: 0.5rem;
    text-align: left;
    font-family: var(--font-family);
    color: var(--main-text-color-lighter);
`;
