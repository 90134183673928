import React, { Fragment, useCallback } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { isValidEmail } from '@SUPPORT/utils';
import { useAlertDialog } from '@STORES/licensee';

import {
    useCurrentUserQuery,
    useGetPAPSeasonsList,
    useCurrentPAPRequest,
    useGetRequestInfoFor
} from '@SUPPORT/queries';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import {
    Chevron,
    BigButtonGrid,
    ItemSelection,
    ItemSelectionTitle,
    DateCampaign
} from '@COMPONENTS/licensee/common/BigButton';

const TitleWithMargin = styled(Title)`
    margin-inline-start: var(--standard-margin);
`;

const ListWithBullets = styled.ul`
    position: relative;
    margin-inline-start: calc(var(--standard-margin) * 2);
    margin-inline-end: var(--standard-margin);
    margin-top: 0.5rem;
    font-size: 1.6rem;
    text-align: left;
    font-family: var(--font-family);
    color: var(--main-text-color-lighter);

    & li {
        list-style-type: disc;
        list-style-position: outside;
        padding-bottom: 6px;
    }
`;

export function RequestUpcoming() {
    const now = new Date();
    const history = useHistory();

    const { data: user, isLoading: isLoadingUser } = useCurrentUserQuery();

    const { data: currentRequest, isLoading: isLoadingPAPRequest } = useCurrentPAPRequest(user ? user.id : null);
    const noCurrentRequest = !isLoading && !currentRequest;
    const label = !user || isLoading ? '' : currentRequest ? '1 demande déposée.' : '';

    const { data: seasons = [], isLoading: isLoadingSeasons } = useGetPAPSeasonsList();
    const season = seasons.find((s) => s.referenceYear === now.getFullYear() + 1);
    const seasonName = season ? `${season.referenceYear - 1}-${season.referenceYear}` : '';

    const { data: info, isLoading: isLoadingInfo } = useGetRequestInfoFor(
        season ? season.uniqueId : null,
        user,
        noCurrentRequest
    );

    const isLoading = isLoadingUser || isLoadingPAPRequest || isLoadingSeasons || isLoadingInfo;

    // const isOnTest =
    //     window.location.hostname.startsWith('test.pescalice.') || window.location.hostname.includes('localhost');

    const showAlert = useAlertDialog();
    const showEmailAlert = useCallback(() => {
        showAlert({
            hasDismiss: false,
            title: 'Email manquant ou invalide',
            messages: [
                'Une adresse email valide est obligatoire pour déposer une demande en ligne pour les campagnes à venir.',
                'Veuillez contacter votre CDPMEM et fournir une adresse email afin de poursuivre la démarche.'
            ]
        });
    }, [showAlert]);

    // AH: force that to true to enable PAP requests deposit *any time* of the year
    const requestsDepositPeriodOpened = now.getMonth() === 0; // January
    const canCreate = !isLoading && !!info && !!season && requestsDepositPeriodOpened;
    const create = useCallback(() => {
        if (user && !isValidEmail(user.email)) {
            showEmailAlert();
        } else {
            history.push('/request/pap/kind');
        }
    }, [user, showEmailAlert, history]);

    const canView = now.getMonth() !== 0;
    const view = useCallback(() => {
        history.push(`/status/pap/season/${season.uniqueId}`);
    }, [season, history]);

    return (
        <Fragment>
            <Main>
                <TopBackButton />

                <Intro>
                    <Title>Vos demandes de timbres Pêche à pied pour la campagne à venir</Title>
                    <SubTitle>{`Campagne Pêche à pied ${seasonName}`}</SubTitle>
                </Intro>
                <BigButtonGrid withMargin>
                    <ItemSelection onClick={canCreate ? create : null} className={classNames({ disabled: !canCreate })}>
                        <Chevron />
                        <ItemSelectionTitle>Déposer une nouvelle demande de timbres</ItemSelectionTitle>
                        <DateCampaign>Du 1er au 31 janvier</DateCampaign>
                        <MainText>{label}</MainText>
                    </ItemSelection>
                    <ItemSelection onClick={canView ? view : null} className={classNames({ disabled: !canView })}>
                        <Chevron />
                        <ItemSelectionTitle>Visualiser l&apos;état du traitement de mes demandes</ItemSelectionTitle>
                        <DateCampaign>A partir du 1er février</DateCampaign>
                    </ItemSelection>
                </BigButtonGrid>

                <TitleWithMargin>Rappels</TitleWithMargin>
                <ListWithBullets>
                    <li>
                        La dépose et la modification de demandes de timbres Pêche à pied peut se faire du 1er janvier au
                        31 janvier.
                    </li>
                    <li>
                        L&apos;état du traitement de votre demande sera visualisable, sans modification, dès le 1er
                        février.
                    </li>
                    <li>
                        Enfin, à partir du 15 avril, la statut de votre demande sera visible sur cette interface. Seule
                        la notification du CRPMEM fait foi pour l&apos;attribution de votre demande. Si votre licence
                        est incomplète ou refusée, vous recevrez un courrier précisant le motif, les délais de
                        régularisation et les voies de recours.
                    </li>
                </ListWithBullets>
            </Main>
        </Fragment>
    );
}
