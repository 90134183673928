import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { Fragment, useCallback } from 'react';

import { getRequestTypeLabel } from '@STORES/common/pescalice';

import { api } from '@SUPPORT/api';
import { useToggle } from '@SUPPORT/hooks';
import { formatLocaleDate } from '@SUPPORT/utils';
import { useCurrentUserQuery, useCurrentPAPRequest, useUpdatePAPRequest, useGetPAPSeason } from '@SUPPORT/queries';

import { Button } from '@COMPONENTS/licensee/common/Button';
import { Footer, FooterResult } from '@COMPONENTS/licensee/common/Footer';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import { Check, BigButtonGrid, ItemSelectionWithCheck } from '@COMPONENTS/licensee/common/BigButton';

const ValidateButton = styled(Button)`
    grid-area: buttonValidate;
`;

const TypeDoc = styled(Title)`
    padding: 0 var(--standard-margin);
    margin-bottom: 0;
    color: var(--dark-blue);
    font-size: 1.8rem;
`;

export function RequiredInfo() {
    const [hasGivenProofOfPermitRequestForSeason, toggleHasGivenProofOfPermitRequestForSeason] = useToggle(false);
    const [ensuresPaidProfessionalOrganizations, toggleEnsuresPaidProfessionalOrganizations] = useToggle(false);
    const [hasObtainedPermitForSeason, toggleHasObtainedPermitForSeason] = useToggle(false);
    const [hasGivenFishingStatisticsForPreviousSeason, toggleHasGivenFishingStatisticsForPreviousSeason] = useToggle(
        false
    );
    const [
        hasGivenProofOfPaymentToProfessionalOrganizations,
        toggleHasGivenProofOfPaymentToProfessionalOrganizations
    ] = useToggle(false);
    const [affiliatedToMSAorEnim, toggleAffiliatedToMSAorEnim] = useToggle(false);
    const [
        hasGivenPhotoIfFirstInstallationBeforeSeasonStart,
        toggleHasGivenPhotoIfFirstInstallationBeforeSeasonStart
    ] = useToggle(false);
    const [knowsDeliberationsForTheSeason, toggleKnowsDeliberationsForTheSeason] = useToggle(false);

    const { data: user } = useCurrentUserQuery();
    const { data: currentRequest } = useCurrentPAPRequest(user ? user.id : null, (req) => {
        toggleHasGivenProofOfPermitRequestForSeason(
            req.administrativeRequirement.hasGivenProofOfPermitRequestForSeason
        );
        toggleEnsuresPaidProfessionalOrganizations(req.administrativeRequirement.ensuresPaidProfessionalOrganizations);
        toggleHasObtainedPermitForSeason(req.administrativeRequirement.hasObtainedPermitForSeason);
        toggleHasGivenFishingStatisticsForPreviousSeason(
            req.administrativeRequirement.hasGivenFishingStatisticsForPreviousSeason
        );
        toggleHasGivenProofOfPaymentToProfessionalOrganizations(
            req.administrativeRequirement.hasGivenProofOfPaymentToProfessionalOrganizations
        );
        toggleAffiliatedToMSAorEnim(req.administrativeRequirement.affiliatedToMSAorEnim);
        toggleHasGivenPhotoIfFirstInstallationBeforeSeasonStart(
            req.administrativeRequirement.hasGivenPhotoIfFirstInstallationBeforeSeasonStart
        );
        toggleKnowsDeliberationsForTheSeason(req.administrativeRequirement.knowsDeliberationsForTheSeason);
    });
    const season = useGetPAPSeason(currentRequest ? currentRequest.season : null);

    const history = useHistory();
    const mutation = useUpdatePAPRequest();
    const next = useCallback(() => {
        mutation.mutate(
            {
                id: currentRequest.uniqueId,
                data: {
                    administrativeRequirement: {
                        hasGivenProofOfPermitRequestForSeason,
                        ensuresPaidProfessionalOrganizations,
                        hasObtainedPermitForSeason,
                        hasGivenFishingStatisticsForPreviousSeason,
                        hasGivenProofOfPaymentToProfessionalOrganizations,
                        affiliatedToMSAorEnim,
                        hasGivenPhotoIfFirstInstallationBeforeSeasonStart,
                        knowsDeliberationsForTheSeason
                    }
                }
            },
            {
                onSuccess: () => {
                    sendPAPLicenseRequestReceipt(user, season, currentRequest).then(() => {
                        history.push('/request/pap/form/recap');
                    });
                }
            }
        );
    }, [
        user,
        season,
        currentRequest,
        hasGivenProofOfPermitRequestForSeason,
        ensuresPaidProfessionalOrganizations,
        hasObtainedPermitForSeason,
        hasGivenFishingStatisticsForPreviousSeason,
        hasGivenProofOfPaymentToProfessionalOrganizations,
        affiliatedToMSAorEnim,
        hasGivenPhotoIfFirstInstallationBeforeSeasonStart,
        knowsDeliberationsForTheSeason,
        mutation,
        history
    ]);

    return (
        <Fragment>
            <Main>
                <TopBackButton />

                <Intro>
                    <Title>Demandes administratives</Title>
                    <SubTitle>Selectionner les éléments appropriés :</SubTitle>
                </Intro>
                <TypeDoc>Attestation sur l&apos;honneur</TypeDoc>
                <BigButtonGrid withMargin>
                    <ItemSelectionWithCheck
                        itemSelected={hasGivenFishingStatisticsForPreviousSeason}
                        onClick={() => toggleHasGivenFishingStatisticsForPreviousSeason()}
                    >
                        <Check />
                        <MainText>
                            J&apos;atteste être à jour de mes statistiques de pêche pour la campagne précédente.
                        </MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={ensuresPaidProfessionalOrganizations}
                        onClick={() => toggleEnsuresPaidProfessionalOrganizations()}
                    >
                        <Check />
                        <MainText>
                            J&apos;atteste être à jour des paiements des contributions professionnelles obligatoires dues aux différents organismes professionnels .
                        </MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={knowsDeliberationsForTheSeason}
                        onClick={() => toggleKnowsDeliberationsForTheSeason()}
                    >
                        <Check />
                        <MainText>
                            J&apos;atteste avoir pris connaissance des délibérations et des conditions d’éligibilité aux licences.
                        </MainText>
                    </ItemSelectionWithCheck>
                </BigButtonGrid>

                <TypeDoc>
                    Pièces à adresser par courrier avec votre réglement à votre CDPMEM pour compléter votre demande
                </TypeDoc>
                <BigButtonGrid withMargin>
                    <ItemSelectionWithCheck
                        itemSelected={hasGivenProofOfPermitRequestForSeason}
                        onClick={() => toggleHasGivenProofOfPermitRequestForSeason()}
                    >
                        <Check />
                        <MainText>Justificatif de demande de permis Pêche à pied pour la campagne en cours.</MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={hasGivenPhotoIfFirstInstallationBeforeSeasonStart}
                        onClick={() => toggleHasGivenPhotoIfFirstInstallationBeforeSeasonStart()}
                    >
                        <Check />
                        <MainText>
                            Photo d&apos;identité récente (en cas de première installation ou nouvelle demande).
                        </MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={hasObtainedPermitForSeason}
                        onClick={() => toggleHasObtainedPermitForSeason()}
                    >
                        <Check />
                        <MainText>Règlement de la licence et du(des) timbre(s).</MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={hasGivenProofOfPaymentToProfessionalOrganizations}
                        onClick={() => toggleHasGivenProofOfPaymentToProfessionalOrganizations()}
                    >
                        <Check />
                        <MainText>Attestation de paiement de la CPO PAP (pour les armateurs hors Bretagne).</MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        itemSelected={affiliatedToMSAorEnim}
                        onClick={() => toggleAffiliatedToMSAorEnim()}
                    >
                        <Check />
                        <MainText>Attestation d&apos;affiliation à la MSA ou à l&apos;ENIM.</MainText>
                    </ItemSelectionWithCheck>
                </BigButtonGrid>
            </Main>
            <Footer>
                <FooterResult />
                <ValidateButton
                    primary
                    onClick={next}
                    disabled={
                        !hasGivenFishingStatisticsForPreviousSeason ||
                        !ensuresPaidProfessionalOrganizations ||
                        !knowsDeliberationsForTheSeason
                    }
                >
                    Suivant
                </ValidateButton>
            </Footer>
        </Fragment>
    );
}

function sendPAPLicenseRequestReceipt(user, season, request) {
    const now = new Date();
    const seasonParts = season.parts || [];
    const lastSeasonPart = seasonParts[seasonParts.length - 1];

    const data = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        depositDate: formatLocaleDate(now, 'dd/MM/yyyy'),
        depositTime: formatLocaleDate(now, 'kk:mm'),
        seasonName: `${request.referenceYear - 1}-${request.referenceYear}`,
        licenseRequestNature: getRequestTypeLabel(request, false).toLowerCase(),
        licenseAmount: request.basePrice.toString(),
        requestBeginDate: lastSeasonPart
            ? formatLocaleDate(new Date(lastSeasonPart.requestBeginDate), 'dd/MM/yyyy')
            : '',
        requestEndDate: lastSeasonPart ? formatLocaleDate(new Date(lastSeasonPart.requestEndDate), 'dd/MM/yyyy') : '',

        extracts: request.extractRequests.map((extract) => {
            const fullExtract = season.extracts.find((ex) => ex.uniqueId === extract.targetedExtract);
            return {
                name: fullExtract.name,
                nature: getRequestTypeLabel(extract, false).toLowerCase(),
                payments:
                    extract.payments && extract.payments.length > 0
                        ? extract.payments.map((payment) => ({ amount: payment.amount.toString() }))
                        : [{ amount: fullExtract.basePrice.toString() }]
            };
        })
    };

    return api.sendLicenseRequestReceiptPAP(data);
}
