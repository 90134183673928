import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import {
    useAlertDialog,
    useDismissAlert,
    useOpenModalDialog,
    useModalDialogState,
    useDismissModalDialog
} from '@STORES/licensee';
import { getSavableDataFromRequest, getRequestTypeLabelForKey, getRequestTypeKey } from '@STORES/common/pescalice';

import { api } from '@SUPPORT/api';
import { useCurrentUserQuery, useCurrentPAPRequest, useGetPAPSeasonsList, useUpdatePAPRequest } from '@SUPPORT/queries';

import { Button } from '@COMPONENTS/licensee/common/Button';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Overlay, DialogBox, ModalTitle } from '@COMPONENTS/licensee/common/Modal';
import { Main, Section, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import { LargeContainerTitle, LargeContainerContent } from '@COMPONENTS/licensee/common/LargeContainer';
import {
    ItemSelectionTitle,
    Check,
    Info,
    InsideInfo,
    ItemSelection,
    BigButtonGrid,
    ItemSelectionWithCheck
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer, FooterResult } from '@COMPONENTS/licensee/common/Footer';

const Content = styled(LargeContainerContent)`
    padding-top: 0;
    margin-bottom: 1rem;
`;

const ItemSelectionHighlight = styled(ItemSelection)`
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

const Zone = styled(Title)`
    padding: 1.2rem var(--standard-margin);
    color: var(--light-gray);
    font-size: 1.8rem;
`;

const Place = styled(MainText)`
    margin-bottom: 0.8rem;
`;

const BottomRow = styled.div`
    margin-top: auto;
    display: flex;
    align-items: center;
`;

const Price = styled(MainText)`
    padding: 0.3rem 0.9rem;
    border-radius: var(--form-elements-border-radius);
    color: black;
    font-weight: 700;
    background: #f3f3f3;
    align-self: flex-start;
`;

const Nature = styled(Price)`
    margin-left: 1rem;
    opacity: 0.5;
    font-weight: 400;
`;

const ValidateButton = styled(Button)`
    grid-area: buttonValidate;
`;

export function RequestForm() {
    const history = useHistory();
    const showAlert = useAlertDialog();
    const dismissAlert = useDismissAlert();

    const { data: user } = useCurrentUserQuery();
    const { data: currentRequest } = useCurrentPAPRequest(user ? user.id : null);

    const { data: seasons = [] } = useGetPAPSeasonsList();
    const lastSeason = currentRequest ? seasons.find((s) => s.referenceYear === currentRequest.referenceYear) : null;

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        if (lastSeason && currentRequest) {
            const allSelected = {};
            currentRequest.extractRequests.forEach((extractReq) => {
                const extract = lastSeason.extracts.find((ex) => ex.uniqueId === extractReq.targetedExtract);
                allSelected[extract.pescaliceIdentifier] = { extract, nature: getRequestTypeKey(extractReq) };
            });
            setSelected(allSelected);
        }
    }, [lastSeason, currentRequest]);

    const preSelectExtracts = useCallback(() => {
        setLoading(true);
        api.requestInfoFor(lastSeason.uniqueId, user).then(({ data }) => {
            setLoading(false);

            const previouslyRenewed = data.probablyRenewedExtractPescaliceIdentifiers || [];
            if (previouslyRenewed.length === 0) {
                return;
            }

            const autoSelect = () => {
                const autoSelected = {};
                (data.probablyRenewedExtractPescaliceIdentifiers || []).forEach((id) => {
                    const extract = lastSeason.extracts.find((ex) => ex.pescaliceIdentifier === id);
                    if (extract) {
                        autoSelected[extract.pescaliceIdentifier] = { extract, nature: 'isExtractRenewal' };
                    }
                });
                setSelected(autoSelected);
            };

            if (Object.keys(selected).length > 0) {
                const message =
                    previouslyRenewed.length === 1
                        ? 'Vos demandes de timbres actuelles vont être remplacées par le timbre obtenu lors de la précédente campagne.'
                        : `Vos demandes de timbres actuelles vont être remplacées par les ${previouslyRenewed.length} timbres obtenus lors de la précédente campagne.`;

                showAlert({
                    hasDismiss: true,
                    title: 'Attention',
                    messages: [message],
                    okCb: () => {
                        autoSelect();
                        dismissAlert();
                    }
                });
            } else {
                autoSelect();
            }
        });
    }, [user, lastSeason, selected, showAlert, dismissAlert]);

    const onToggle = useCallback(
        (extract, nature) => {
            const id = extract.pescaliceIdentifier;
            if (selected[id]) {
                delete selected[id];
                setSelected({ ...selected });
            } else {
                setSelected({ ...selected, [id]: { extract, nature } });
            }
        },
        [selected]
    );

    const mutation = useUpdatePAPRequest();
    const next = useCallback(() => {
        const extracts = Object.keys(selected).map((key) => {
            const extract = lastSeason.extracts.find((ex) => ex.pescaliceIdentifier === key);
            const requestedExtract = currentRequest.extractRequests.find(
                (ex) => ex.targetedExtract === extract.uniqueId
            );
            const nature = selected[key].nature;
            const extractInfo = {
                targetedExtract: extract.uniqueId,
                isExtractRenewal: nature === 'isExtractRenewal',
                isFirstInstallation: nature === 'isFirstInstallation',
                isNewExtractRequest: nature === 'isNewExtractRequest'
            };

            if (!requestedExtract || !requestedExtract.payments || requestedExtract.payments.length === 0) {
                // do not provide any payment
            } else {
                extractInfo.payments = requestedExtract.payments.map((p) => {
                    const payment = { ...p };
                    if (!payment.reference) {
                        payment.reference = '<en attente de reception>';
                    }
                    return payment;
                });
            }

            return extractInfo;
        });

        const data = {
            ...getSavableDataFromRequest(currentRequest),
            extractRequests: extracts
        };

        mutation.mutate(
            { id: currentRequest.uniqueId, data },
            {
                onSuccess: () => history.push('/request/pap/form/info')
            }
        );
    }, [currentRequest, lastSeason, selected, mutation, history]);

    const content = [];
    let currentDiv = '';
    let currentGrid = [];
    let currentGridKey = 0;

    if (lastSeason) {
        if (currentRequest.isLicenseRenewal) {
            content.push(
                <BigButtonGrid key="last" disabled={loading}>
                    <ItemSelectionHighlight onClick={preSelectExtracts}>
                        <ItemSelectionTitle>
                            Demander les timbres obtenus lors de la précédente campagne, en renouvellement
                        </ItemSelectionTitle>
                    </ItemSelectionHighlight>
                </BigButtonGrid>
            );
        }

        lastSeason.extracts.forEach((extract) => {
            if (extract.administrativeDivisionName !== currentDiv) {
                if (currentGrid.length > 0) {
                    content.push(<BigButtonGrid key={'grid-' + currentGridKey}>{currentGrid}</BigButtonGrid>);
                    currentGrid = [];
                }

                currentGridKey = extract.administrativeDivisionZipCode;
                currentDiv = extract.administrativeDivisionName;
                content.push(
                    <Zone key={extract.administrativeDivisionZipCode}>
                        {extract.administrativeDivisionName} {extract.administrativeDivisionZipCode}
                    </Zone>
                );
            }

            const selectionInfo = selected[extract.pescaliceIdentifier];
            const selectionNature = selectionInfo ? selectionInfo.nature : null;
            currentGrid.push(
                <ExtractSlot
                    extract={extract}
                    selected={!!selectionInfo}
                    selectionNature={selectionNature}
                    onToggle={onToggle}
                    key={extract.pescaliceIdentifier}
                />
            );
        });

        if (currentGrid.length > 0) {
            const lastExtract = lastSeason.extracts[lastSeason.extracts.length - 1];
            content.push(
                <BigButtonGrid key={'grid-' + lastExtract.administrativeDivisionZipCode}>{currentGrid}</BigButtonGrid>
            );
            currentGrid = [];
        }
    }

    return (
        <Fragment>
            <Main>
                <TopBackButton />
                <Section>
                    <Intro>
                        <Title>Pêche à pied</Title>
                        <SubTitle>Selectionner le(s) timbre(s) que vous souhaitez demander.</SubTitle>
                        <LargeContainerTitle>Demande de timbre(s)</LargeContainerTitle>
                        <Content>{content}</Content>
                    </Intro>
                </Section>

                {/*<Modal
                title="Toto"
                messages={[
                    'Votre demande de timbre Coques - Baie de Locquirec est actuellement en cours de traitement et ne pourra plus être redemandée.',
                    'Voulez-vous vraiment annuler cette demande ?'
                ]}
            />*/}
            </Main>
            <Footer>
                <FooterResult>
                    <TotalLine extracts={Object.values(selected)} />
                </FooterResult>
                <ValidateButton primary disabled={Object.values(selected).length === 0} onClick={next}>
                    Suivant
                </ValidateButton>
            </Footer>

            <ExtractNatureDialog />
        </Fragment>
    );
}

function ExtractSlot({ extract, selected, selectionNature, onToggle }) {
    const openModalDialog = useOpenModalDialog();
    const [showInfo, setShowInfo] = useState(false);

    const onClick = useCallback(() => {
        if (selected) {
            onToggle(extract);
        } else {
            openModalDialog('EXTRACT-NATURE', (nature) => {
                if (nature) {
                    onToggle(extract, nature);
                }
            });
        }
    }, [selected, openModalDialog, extract, onToggle]);

    const toggleInfo = useCallback((evt) => {
        evt.stopPropagation();
        setShowInfo((inf) => !inf);
    }, []);

    return (
        <ItemSelectionWithCheck onClick={onClick} itemSelected={selected} infoRequested={showInfo}>
            <Check />
            {extract && extract.additionalMetadata && extract.additionalMetadata.notes && <Info onClick={toggleInfo} />}
            <ItemSelectionTitle>{extract.name}</ItemSelectionTitle>
            <Place>{extract.shore}</Place>
            <BottomRow>
                <Price>{extract.basePrice}€</Price>
                {selectionNature && <Nature>{getRequestTypeLabelForKey(selectionNature, false)}</Nature>}
            </BottomRow>
            {extract && extract.additionalMetadata && extract.additionalMetadata.notes && (
                <InsideInfo onClick={toggleInfo}>
                    {extract.additionalMetadata.notes.map((note, i) => (
                        <p key={i}>{note}</p>
                    ))}
                </InsideInfo>
            )}
        </ItemSelectionWithCheck>
    );
}

function TotalLine({ extracts }) {
    if (extracts.length === 0) {
        return 'Total: aucun timbre sélectionné';
    }

    const total = extracts.reduce((t, ex) => t + ex.extract.basePrice, 0);
    return `Total: ${extracts.length} timbres (${total} €)`;
}

const makeEmptyPayment = (extract) => ({
    reference: '<en attente de reception>',
    amount: extract.basePrice,
    method: 'kPSPaymentMethodCheck'
});

// Extract nature modal dialog

const DialogContent = styled(Content)`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-around;
    padding-top: 22px;
    padding-bottom: 22px;
    margin-bottom: 0;
    gap: 20px;

    & button {
        height: 44px;
        border-radius: 8px;
        font-weight: 600;
    }

    & button.orange {
        background: var(--orange);
    }
`;

export function ExtractNatureDialog() {
    const { key, data: cb } = useModalDialogState();
    const dismissDialog = useDismissModalDialog();

    const { data: user } = useCurrentUserQuery();
    const { data: currentRequest } = useCurrentPAPRequest(user ? user.id : null);

    const enableRenewal = !!currentRequest && (currentRequest.isNewLicenseRequest || currentRequest.isLicenseRenewal);
    const enableNewExtract =
        !!currentRequest && (currentRequest.isNewLicenseRequest || currentRequest.isLicenseRenewal);
    const enableFirstInstall =
        !!currentRequest && (currentRequest.isNewLicenseRequest || currentRequest.isFirstInstallation);

    const dismiss = useCallback(
        (nature) => {
            cb(nature);
            dismissDialog();
        },
        [cb, dismissDialog]
    );

    return (
        <Overlay show={key === 'EXTRACT-NATURE'}>
            <DialogBox>
                <ModalTitle>Nature de la demande</ModalTitle>
                <DialogContent>
                    <Button
                        onClick={enableRenewal ? () => dismiss('isExtractRenewal') : null}
                        disabled={!enableRenewal}
                    >
                        Renouvellement
                    </Button>
                    <Button
                        onClick={enableNewExtract ? () => dismiss('isNewExtractRequest') : null}
                        disabled={!enableNewExtract}
                    >
                        Nouvelle demande
                    </Button>
                    <Button
                        onClick={enableFirstInstall ? () => dismiss('isFirstInstallation') : null}
                        disabled={!enableFirstInstall}
                    >
                        1ère installation
                    </Button>
                    <Button className="orange" onClick={() => dismiss(null)}>
                        Timbre pas demandé
                    </Button>
                </DialogContent>
            </DialogBox>
        </Overlay>
    );
}
