import styled from 'styled-components';

export const TableTwoColumns = styled.table`
    margin-bottom: 2rem;

    & td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 0.1rem solid var(--super-light-gray);
        font: var(--font-form-text);
    }

    & td.bold {
        font-weight: bold;
    }

    & td:last-of-type {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        text-align: end;
    }
`;
