import React from 'react';
import { useHistory } from 'react-router';

import { userFullName } from '@SUPPORT/utils';
import { useCurrentUserQuery, useCurrentUserCompaniesListQuery } from '@SUPPORT/queries';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import { ItemSelection, ItemSelectionTitle, BigButtonGrid, Chevron } from '@COMPONENTS/licensee/common/BigButton';

export function ProfileSelector() {
    const { data: user } = useCurrentUserQuery(true);
    const { data: companies = [] } = useCurrentUserCompaniesListQuery(user ? user.id : null);

    const history = useHistory();

    return (
        <Main>
            <TopBackButton />

            <Intro>
                <Title>Informations personnelles et professionnelles</Title>
                <SubTitle>Sélectionnez l'entité pour laquelle vous désirez afficher les informations&nbsp;:</SubTitle>
            </Intro>

            <BigButtonGrid withMargin>
                <ItemSelection onClick={() => history.push('/profile/user')}>
                    <Chevron />
                    <ItemSelectionTitle>{userFullName(user)}</ItemSelectionTitle>
                </ItemSelection>

                {companies.map((company) => (
                    <ItemSelection onClick={() => history.push(`/profile/company/${company.id}`)} key={company.id}>
                        <Chevron />
                        <ItemSelectionTitle>{company.name}</ItemSelectionTitle>
                    </ItemSelection>
                ))}
            </BigButtonGrid>
        </Main>
    );
}
