import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { useGetCurrentPEPProxy } from '@SUPPORT/queries';
import { loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';

import { CancelButton, NextButton } from './common';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Section, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import {
    Check,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

export function RequestMode() {
    const history = useHistory();
    const { seasonality } = useParams();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);
    const currentProxy = useGetCurrentPEPProxy(currentRequest);

    const [selectedMode, selectMode] = useState('licence');
    useEffect(() => {
        if (currentProxy && currentProxy.seasons[0].license.additionalMetadata.wizardInitialStepOptionAccessoryView) {
            selectMode(currentProxy.seasons[0].license.additionalMetadata.modeLicenceExtraits);
        }
    }, [currentProxy]);

    const onBack = useCallback(() => {
        if (currentRequest) {
            delete currentRequest.additionalMetadata;
            saveTemporaryPEPRequest(currentRequest);
        }
    }, []);

    const next = useCallback(() => {
        if (!currentRequest) {
            alert('Erreur inconnue');
            history.push('/');
            return;
        }

        currentRequest.additionalMetadata = { modeLicenceExtraits: selectedMode };
        saveTemporaryPEPRequest(currentRequest);
        history.push(`/request/pep/${seasonality}/admin`);
    }, [selectedMode, currentRequest, history, seasonality]);

    return (
        <Fragment>
            <Main>
                <TopBackButton onBack={onBack} />
                <Section>
                    <Intro>
                        <Title>Mode d&apos;attribution pour votre demande de licences pour la pêche embarquée</Title>
                        <SubTitle>
                            Veuillez sélectionner le mode d&apos;attribution pour la licence :{' '}
                            {currentProxy ? currentProxy.name : ''}
                        </SubTitle>
                    </Intro>

                    <Fragment>
                        <BigButtonGrid withMargin>
                            <ItemSelectionWithCheck
                                itemSelected={selectedMode === 'licence'}
                                onClick={() => selectMode('licence')}
                                key="licence"
                            >
                                <Check />
                                <ItemSelectionTitle>Licence</ItemSelectionTitle>
                            </ItemSelectionWithCheck>
                            <ItemSelectionWithCheck
                                itemSelected={selectedMode === 'extrait'}
                                onClick={() => selectMode('extrait')}
                                key="extrait"
                            >
                                <Check />
                                <ItemSelectionTitle>Extrait</ItemSelectionTitle>
                            </ItemSelectionWithCheck>
                        </BigButtonGrid>
                    </Fragment>
                </Section>
            </Main>
            <Footer>
                <CancelButton />
                <NextButton primary onClick={next}>
                    Suivant
                </NextButton>
            </Footer>
        </Fragment>
    );
}
