import { useHistory, useParams } from 'react-router';
import React, { Fragment, useState } from 'react';

import { useCurrentUserQuery, useCurrentUsersBoatsListQuery } from '@SUPPORT/queries';

import { NextButton } from '@COMPONENTS/licensee/pages/pep/common';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, MainText } from '@COMPONENTS/licensee/common/Main';
import {
    BigButtonGrid,
    ItemSelectionTitle,
    Check,
    ItemSelectionWithCheck
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

export function StatusBoatSelector() {
    const history = useHistory();

    const { data: user } = useCurrentUserQuery();
    const { data: boats = [] } = useCurrentUsersBoatsListQuery(user ? user.id : null);
    const { seasonality } = useParams();

    const [selectedBoatId, selectBoat] = useState(null);
    const next = () => {
        localStorage.setItem('pep-status-boat', selectedBoatId);
        if (seasonality) {
            history.push(`/request/pep/${seasonality}/status/list`);
        } else {
            history.push('/status/pep/list');
        }
    };

    return (
        <Fragment>
            <Main>
                <TopBackButton />
                <Intro>
                    <Title>Statut de vos licences Pêche Embarquée</Title>
                    <MainText>Sélectionnez le navire concerné :</MainText>
                </Intro>
                <BigButtonGrid withMargin>
                    {boats.map(({ boat }) => (
                        <ItemSelectionWithCheck
                            itemSelected={selectedBoatId === boat.id}
                            onClick={() => selectBoat(boat.id)}
                            key={boat.id}
                        >
                            <Check />
                            <ItemSelectionTitle>{boat.name}</ItemSelectionTitle>
                        </ItemSelectionWithCheck>
                    ))}
                </BigButtonGrid>
            </Main>
            <Footer>
                <NextButton primary onClick={next} disabled={selectedBoatId === null}>
                    Suivant
                </NextButton>
            </Footer>
        </Fragment>
    );
}
