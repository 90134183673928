import styled from 'styled-components';

export const Footer = styled.footer`
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: var(--standard-margin);
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    padding: calc(var(--standard-margin) * 0.7) var(--standard-margin);
    background: var(--main-background);

    /* @media (max-width: 812px) {
        & {
            flex-direction: column;
        }
    } */

    // Shadow
    &::before {
        content: '';
        position: absolute;
        top: -0.8rem;
        left: 0;
        right: 0;
        height: 0.8rem;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.04) 80%,
            rgba(0, 0, 0, 0.15) 100%
        );
    }
`;

export const FooterResult = styled.p`
    position: relative;
    display: flex;
    flex-shrink: 0;
    margin-top: 0.2rem;
    margin-right: auto;
    color: var(--dark-blue);
    font: var(--font-button);
    font-weight: 600;
    text-align: center;

    @media (max-width: 812px) {
        & {
            margin-left: auto;
            margin-bottom: -1rem;
        }
    }
`;
