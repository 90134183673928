import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { getRequestTypeKey } from '@STORES/common/pescalice';
import { useAlertDialog, useDismissAlert } from '@STORES/licensee';

import { formatLocaleDate } from '@SUPPORT/utils';
import {
    useCurrentUserQuery,
    useUserAvatarImages,
    useCurrentPAPRequest,
    useGetPAPSeasonsList,
    useGetRequestInfoFor,
    useCreatePAPRequest,
    useDeletePAPRequest
} from '@SUPPORT/queries';

import { Button } from '@COMPONENTS/licensee/common/Button';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import {
    Check,
    BigButtonGrid,
    ItemSelection,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

const ValidateButton = styled(Button)`
    margin-left: auto;
`;

const MainTextAlt = styled(MainText)`
    margin-left: var(--standard-margin);
    margin-right: var(--standard-margin);
`;

export function RequestKind() {
    const history = useHistory();

    const { data: user } = useCurrentUserQuery();
    const { data: images } = useUserAvatarImages(user ? user.id : null);
    const { data: currentRequest, isLoading } = useCurrentPAPRequest(user ? user.id : null);
    const noCurrentRequest = !isLoading && !currentRequest;

    const { data: seasons = [] } = useGetPAPSeasonsList(noCurrentRequest);
    const season = seasons[0];
    const openingDate = season ? new Date(season.beginDate) : new Date();
    const closingDate = season ? new Date(season.endDate) : new Date();

    const { data: info } = useGetRequestInfoFor(season ? season.uniqueId : null, user, noCurrentRequest);
    const enabled = noCurrentRequest && !!info;

    const [kind, setKind] = useState(null);
    useEffect(() => {
        if (currentRequest) {
            setKind(getRequestTypeKey(currentRequest));
        }
    }, [currentRequest]);

    const creation = useCreatePAPRequest();
    const next = useCallback(() => {
        if (currentRequest) {
            history.push('/request/pap/form');
        } else {
            const photoURL = images && images.length > 0 ? images[0].url : null;
            const data = {
                depositDate: new Date(),
                isNewLicenseRequest: kind === 'isNewLicenseRequest',
                isLicenseRenewal: kind === 'isLicenseRenewal',
                isFirstInstallation: kind === 'isFirstInstallation',
                papPermitNumber: user.uniqueIdentifier,
                season: season.uniqueId,
                requestAuthor: {
                    telecapecheId: user.id,
                    [user.socialRegime.toLowerCase()]: user.licenseNum || '',
                    photoURL
                },
                administrativeRequirement: {
                    hasGivenFishingStatisticsForPreviousSeason: false,
                    knowsDeliberationsForTheSeason: false,
                    hasGivenProofOfPermitRequestForSeason: false,
                    hasObtainedPermitForSeason: false,
                    hasGivenProofOfPaymentToProfessionalOrganizations: false,
                    affiliatedToMSAorEnim: false,
                    hasGivenPhotoIfFirstInstallationBeforeSeasonStart: false
                }
            };

            creation.mutate(data, {
                onSuccess: () => history.push('/request/pap/form')
            });
        }
    }, [user, images, season, kind, currentRequest, creation, history]);

    const seasonName = season ? `${season.referenceYear - 1}-${season.referenceYear}` : '';

    const deleteMutation = useDeletePAPRequest();
    const showAlert = useAlertDialog();
    const dismissAlert = useDismissAlert();
    const deleteRequest = useCallback(() => {
        showAlert({
            title: 'Annuler la demande',
            okLabel: "Confirmer l'annulation",
            messages: [
                `Je souhaite annuler et supprimer ma demande pour la campagne à venir Pêche à Pied ${seasonName}.`,
                `Je pourrai si besoin déposer à nouveau une demande avant le 31/01/${new Date().getFullYear()}`
            ],

            okCb: () => {
                deleteMutation.mutate(
                    { id: currentRequest.uniqueId },
                    {
                        onSuccess: () => {
                            history.replace('/');
                            dismissAlert();
                        }
                    }
                );
            }
        });
    }, [currentRequest, showAlert, deleteMutation, dismissAlert, seasonName, history]);

    return (
        <Fragment>
            <Main>
                <TopBackButton />

                <Intro>
                    <Title>Nature de votre demande de licence pour la pêche à pied</Title>
                    <SubTitle>
                        {season
                            ? `Campagne ${seasonName} - Date d'ouverture : ${formatLocaleDate(
                                  openingDate,
                                  'dd/MM/yyyy'
                              )} - Date de fermeture : ${formatLocaleDate(closingDate, 'dd/MM/yyyy')}`
                            : 'Campagne'}
                    </SubTitle>
                    {currentRequest && (
                        <SubTitle>
                            Dernière date de dépôt: {formatLocaleDate(currentRequest.depositDate, 'dd/MM/yyyy')}
                        </SubTitle>
                    )}
                </Intro>
                <BigButtonGrid withMargin>
                    <ItemSelectionWithCheck
                        className={classNames({ disabled: !enabled })}
                        itemSelected={kind === 'isLicenseRenewal'}
                        onClick={enabled ? () => setKind('isLicenseRenewal') : null}
                    >
                        <Check />
                        <ItemSelectionTitle>Renouvellement</ItemSelectionTitle>
                        <MainText>
                            Vous déteniez déjà un permis PAP et une licence lors de la campagne précédente.
                        </MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        className={classNames({ disabled: !enabled })}
                        itemSelected={kind === 'isNewLicenseRequest'}
                        onClick={enabled ? () => setKind('isNewLicenseRequest') : null}
                    >
                        <Check />
                        <ItemSelectionTitle>Nouvelle demande</ItemSelectionTitle>
                        <MainText>
                            Vous avez déjà détenu un permis PAP par le passé mais pas pour la campagne précédente.
                        </MainText>
                    </ItemSelectionWithCheck>
                    <ItemSelectionWithCheck
                        className={classNames({ disabled: !enabled })}
                        itemSelected={kind === 'isFirstInstallation'}
                        onClick={enabled ? () => setKind('isFirstInstallation') : null}
                    >
                        <Check />
                        <ItemSelectionTitle>1ère installation</ItemSelectionTitle>
                        <MainText>
                            Vous déposez une demande de PAP pour la première fois pour la campagne en cours
                        </MainText>
                    </ItemSelectionWithCheck>

                    {currentRequest && (
                        <ItemSelection warning={true} onClick={deleteRequest}>
                            <ItemSelectionTitle>Annuler ma demande</ItemSelectionTitle>
                            <MainText>Annuler la demande en cours pour la campagne {seasonName}.</MainText>
                        </ItemSelection>
                    )}
                </BigButtonGrid>
                <MainTextAlt>
                    En déposant une demande pour des timbres en pêche à pied, vous devez vous acquitter du montant de la
                    licence Pêche à pied de 65 €.
                </MainTextAlt>
            </Main>
            <Footer>
                <ValidateButton primary onClick={kind ? next : null} disabled={!kind}>
                    Suivant
                </ValidateButton>
            </Footer>
        </Fragment>
    );
}
