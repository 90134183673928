import { atom } from 'jotai';
import { useUpdateAtom, useAtomValue } from 'jotai/utils';
import { useCallback } from 'react';

const NONE = 'NONE';
const ALERT = 'ALERT';

const currentModalDialogState = atom({ key: NONE, data: null });

export function useModalDialogState() {
    return useAtomValue(currentModalDialogState);
}

export function useOpenModalDialog() {
    const setModalDialogState = useUpdateAtom(currentModalDialogState);
    return useCallback((key, data) => setModalDialogState({ key, data }), [setModalDialogState]);
}

export function useDismissModalDialog() {
    const setModalDialogState = useUpdateAtom(currentModalDialogState);
    return useCallback(() => setModalDialogState({ key: NONE, data: null }), [setModalDialogState]);
}

export function useAlertDialog() {
    const showDialog = useOpenModalDialog();
    return useCallback(
        (data) =>
            showDialog(ALERT, {
                title: '',
                messages: [],
                okLabel: 'Ok',
                dismissLabel: 'Annuler',
                hasDismiss: true,
                ...data
            }),
        [showDialog]
    );
}

export function useDismissAlert() {
    const dismissDialog = useDismissModalDialog();
    return useCallback(() => dismissDialog(ALERT), [dismissDialog]);
}
