import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import '@CSS/licensee/reset.css';
import '@CSS/licensee/variables.css';

import { Header } from './Header';
import { Welcome } from './pages/Welcome';
import { StatusSelector } from './pages/StatusSelector';
import { StatusSeasonSelector } from './pages/StatusSeasonSelector';
import { StatusFinal } from './pages/StatusFinal';
import { StatusCompanySelector } from './pages/StatusCompanySelector';
import { StatusBoatSelector } from './pages/StatusBoatSelector';
import { StatusFinalPEP } from './pages/StatusFinalPEP';
import { UserProfile } from './pages/Profile';
import { CompanyProfile } from './pages/Profile';
import { ProfileSelector } from './pages/ProfileSelector';
import { RequestSelector } from './pages/RequestSelector';

import { RequestUpcoming } from './pages/pap/RequestUpcoming';
import { RequestKind } from './pages/pap/RequestKind';
import { RequestForm } from './pages/pap/RequestForm';
import { RequiredInfo } from './pages/pap/RequiredInfo';
import { RequestRecapPAP } from './pages/pap/RequestRecap';

import { RequestMenu } from './pages/pep/RequestMenu';
import { RequestCompany } from './pages/pep/RequestCompany';
import { RequestBoat } from './pages/pep/RequestBoat';
import { RequestLicense } from './pages/pep/RequestLicense';
import { RequestNature } from './pages/pep/RequestNature';
import { RequestOptions } from './pages/pep/RequestOptions';
import { RequestMode } from './pages/pep/RequestMode';
import { RequestZones } from './pages/pep/RequestZones';
import { RequestAdmin } from './pages/pep/RequestAdmin';
import { RequestRecapPEP } from './pages/pep/RequestRecap';

import { AlertDialog } from '@COMPONENTS/licensee/common/Modal';
import { ErrorBoundary } from '@COMPONENTS/common/ErrorBoundary';
import { PaymentInfoDialog } from '@COMPONENTS/licensee/PaymentInfoDialog';

import { useOpenModalDialog } from '@STORES/licensee';
import { useCurrentUserQuery, useCurrentUserCompaniesListQuery } from '@SUPPORT/queries';

export function LicenseeRoot() {
    const { data: user, isFetched: isUserFetched } = useCurrentUserQuery(true);
    const { data: companies = [], isFetched: isCompaniesFetched } = useCurrentUserCompaniesListQuery(
        user ? user.id : null
    );

    const openModal = useOpenModalDialog();
    useEffect(() => {
        if (isUserFetched && isCompaniesFetched) {
            let doesNotHaveCompleteBankInfos = true;

            const bankInfos = user && user.bankInfos ? JSON.parse(user.bankInfos) : null;
            if (!bankInfos) {
                doesNotHaveCompleteBankInfos = true;
            } else {
                doesNotHaveCompleteBankInfos = bankInfos.length !== companies.length + 1;
            }

            if (doesNotHaveCompleteBankInfos) {
                openModal('PAYMENT-INFO');
            }
        }
    }, [isUserFetched, user, isCompaniesFetched, companies]);

    return (
        <ErrorBoundary>
            <Header />
            <Switch>
                <Route exact path="/" component={Welcome} />
                <Route path="/profile/select" component={ProfileSelector} />
                <Route path="/profile/user" component={UserProfile} />
                <Route path="/profile/company/:id" component={CompanyProfile} />

                <Route exact path="/status" component={StatusSelector} />
                <Route exact path="/status/pap/season" component={StatusSeasonSelector} />
                <Route exact path="/status/pap/season/:id" component={StatusFinal} />
                <Route exact path="/status/pep/company" component={StatusCompanySelector} />
                <Route exact path="/status/pep/boat" component={StatusBoatSelector} />
                <Route exact path="/status/pep/list" component={StatusFinalPEP} />

                <Route exact path="/request" component={RequestSelector} />

                <Route exact path="/request/pap/upcoming" component={RequestUpcoming} />
                <Route exact path="/request/pap/kind" component={RequestKind} />
                <Route exact path="/request/pap/form" component={RequestForm} />
                <Route exact path="/request/pap/form/info" component={RequiredInfo} />
                <Route exact path="/request/pap/form/recap" component={RequestRecapPAP} />

                <Route exact path="/request/pep/:seasonality" component={RequestMenu} />
                <Route exact path="/request/pep/:seasonality/company" component={RequestCompany} />
                <Route exact path="/request/pep/:seasonality/boat" component={RequestBoat} />
                <Route exact path="/request/pep/:seasonality/license" component={RequestLicense} />
                <Route exact path="/request/pep/:seasonality/nature" component={RequestNature} />
                <Route exact path="/request/pep/:seasonality/options" component={RequestOptions} />
                <Route exact path="/request/pep/:seasonality/mode" component={RequestMode} />
                <Route exact path="/request/pep/:seasonality/zones" component={RequestZones} />
                <Route exact path="/request/pep/:seasonality/admin" component={RequestAdmin} />
                <Route exact path="/request/pep/:seasonality/recap" component={RequestRecapPEP} />
                <Route exact path="/request/pep/:seasonality/status/company" component={StatusCompanySelector} />
                <Route exact path="/request/pep/:seasonality/status/boat" component={StatusBoatSelector} />
                <Route exact path="/request/pep/:seasonality/status/list" component={StatusFinalPEP} />
            </Switch>

            <AlertDialog />
            <PaymentInfoDialog />
        </ErrorBoundary>
    );
}
