import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { useCurrentUserQuery, useGetUserPAPRequests } from '@SUPPORT/queries';

import { Main, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import { ItemSelection, ItemSelectionTitle, BigButtonGrid, Chevron } from '@COMPONENTS/licensee/common/BigButton';

export function StatusSelector() {
    return (
        <Main>
            <TopBackButton />
            <Intro>
                <Title>Campagnes en cours</Title>
                <SubTitle>
                    Sélectionnez le type de pêche sur lequel vous désirez connaître le statut de vos licences :
                </SubTitle>
            </Intro>
            <BigButtonGrid withMargin>
                <SelectorPAP />
                <SelectorPEP />
            </BigButtonGrid>
        </Main>
    );
}

const SelectorPAP = () => {
    const history = useHistory();
    const { data: user } = useCurrentUserQuery();
    const enablePAP = !!user && user.tags && user.tags.includes('psc_pap');

    const { isLoading, data: seasons = [] } = useGetUserPAPRequests(user ? user.id : null);

    const now = new Date();
    const lastSeason = seasons.find(
        (s) => s.referenceYear === (now.getMonth() <= 4 ? now.getFullYear() : now.getFullYear() + 1)
    );

    let message;
    if (!lastSeason || isLoading) {
        message = '';
    } else if (lastSeason.licenseRequest.extractRequests.length === 0) {
        message = "Vous n'avez actuellement aucun timbre actif.";
    } else if (lastSeason.licenseRequest.extractRequests.length === 1) {
        message = 'Vous avez actuellement 1 timbre actif.';
    } else {
        message = `Vous avez actuellement ${lastSeason.licenseRequest.extractRequests.length} timbres attribués pour la campagne en cours.`;
    }

    return (
        <ItemSelection
            onClick={enablePAP ? () => history.push('/status/pap/season') : null}
            className={classNames({ disabled: !enablePAP })}
        >
            <ItemSelectionTitle>Pêche à pied</ItemSelectionTitle>
            <MainText>{message}</MainText>
            <Chevron />
        </ItemSelection>
    );
};

const SelectorPEP = () => {
    const history = useHistory();
    const { data: user } = useCurrentUserQuery();
    const enablePEP = !!user && user.tags && user.tags.includes('psc') && user.tags.includes('pep');

    const next = () => {
        localStorage.setItem('pep-status-company', null);
        localStorage.setItem('pep-status-boat', null);
        history.push('/status/pep/company');
    };

    return (
        <ItemSelection onClick={enablePEP ? next : null} className={classNames({ disabled: !enablePEP })}>
            <ItemSelectionTitle>Pêche embarquée</ItemSelectionTitle>
            <MainText></MainText>
            <Chevron />
        </ItemSelection>
    );
};
