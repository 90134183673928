import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { isValidIBAN, electronicFormatIBAN, friendlyFormatIBAN, isValidBIC } from 'ibantools';

import { useAlertDialog } from '@STORES/licensee';
import { useDismissAlert } from '@STORES/licensee';

import { api } from '@SUPPORT/api';
import { userFullName, formatLocaleDate, formatPhoneNumber, downloadFileFromBase64Data } from '@SUPPORT/utils';
import { useCurrentUserQuery, useUserBankInfo, useGetCompanyQuery, useUserBankInfoMutation } from '@SUPPORT/queries';

import { Label } from '@COMPONENTS/licensee/common/Label';
import { UserAvatar } from '@COMPONENTS/licensee/common/UserAvatar';
import { TextInput } from '@COMPONENTS/licensee/common/TextInput';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Button } from '@COMPONENTS/licensee/common/Button';
import { Main, Section, Intro } from '@COMPONENTS/licensee/common/Main';
import { LargeContainerTitle, LargeContainerContent } from '@COMPONENTS/licensee/common/LargeContainer';

const UserInfo = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const ProfileName = styled.div`
    position: relative;
    margin-left: ${({ $withAvatar }) => ($withAvatar ? 'var(--standard-margin)' : 0)};
    font: var(--font-title-normal);
    color: var(--dark-blue);
`;

const FormTitle = styled.h3`
    position: relative;
    display: block;
    margin: 15px var(--standard-margin) 2px var(--standard-margin);
    font-size: 18px;
    font-weight: 600;
    font-family: var(--font-family);
    color: var(--light-blue);
`;

const Form = styled.form`
    position: relative;
    left: 0.1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--form-elements-width), 1fr));
    grid-column-gap: var(--standard-margin);
    margin: 1rem var(--standard-margin);

    ${Label} {
        padding-left: 0.7rem;
    }
`;

const FormItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.7rem;
`;

const FullWidth = styled.div`
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    gap: 6px;
    margin-top: 2px;
    margin-bottom: 0;
`;

const LargeContainerHeader = styled(LargeContainerTitle)`
    margin: 0 var(--standard-margin);
`;

const LargeContainer = styled(LargeContainerContent)`
    margin: 0 var(--standard-margin) var(--standard-margin) var(--standard-margin);
    padding: 0;
`;

const BankAccountContainer = styled(LargeContainer)`
    position: relative;
    padding: 0 var(--standard-margin) calc(var(--standard-margin) * 0.5) var(--standard-margin);
`;

const FormBankAccount = styled(Form)`
    margin-left: 0;
`;

export const InfoError = styled.h4`
    position: relative;
    display: block;
    margin: 0 var(--standard-margin) 1rem var(--standard-margin);
    flex-shrink: 0;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: var(--font-family);
    color: var(--light-gray);

    & span {
        display: block;
        color: black;
        font-weight: 600;
    }
`;

export function UserProfile() {
    const { data: user } = useCurrentUserQuery();
    const { data: bankInfos = [] } = useUserBankInfo(user ? user.id : 0);

    return (
        <Main>
            <TopBackButton />

            <Section>
                <Intro>
                    <UserInfo>
                        <UserAvatar userId={user ? user.id : null} />
                        <ProfileName $withAvatar>{user ? userFullName(user, false) : ''}</ProfileName>
                    </UserInfo>
                </Intro>

                <FormTitle>Informations personnelles</FormTitle>
                <Form>
                    <FormItem>
                        <Label>Nom :</Label>
                        <TextInput value={user ? user.lastName : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Prénom :</Label>
                        <TextInput value={user ? user.firstName : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Date de naissance :</Label>
                        <TextInput
                            value={user && user.birthDate ? formatLocaleDate(user.birthDate, 'P') : ''}
                            readOnly
                        />
                    </FormItem>
                    <FormItem>
                        <Label>Identifiant Pescalice :</Label>
                        <TextInput value={user ? user.identifier : ''} readOnly />
                    </FormItem>
                </Form>

                <FormTitle>Informations de contact</FormTitle>
                <Form>
                    <FormItem>
                        <Label>Rue :</Label>
                        <TextInput value={user ? user.address1 : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Rue (complément) :</Label>
                        <TextInput value={user ? user.address2 : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Code Postal :</Label>
                        <TextInput value={user ? user.zipCode : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Commune :</Label>
                        <TextInput value={user ? user.city : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Adresse email :</Label>
                        <TextInput value={user ? user.email : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Tel mobile :</Label>
                        <TextInput value={user ? formatPhoneNumber(user.mobileNum1) : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Ligne fixe :</Label>
                        <TextInput value={user ? formatPhoneNumber(user.phoneNum) : ''} readOnly />
                    </FormItem>
                </Form>

                <LargeContainerHeader>Informations de paiement</LargeContainerHeader>
                <BankAccountContainer>
                    <BankInfoForm user={user} company={null} bankInfo={bankInfos.find((info) => !info.companyId)} />
                </BankAccountContainer>

                <InfoError>
                    <span>Une erreur dans vos informations ?</span>
                    Contactez votre CDPMEM pour rectification. Si votre navire est immatriculé hors Bretagne ou si vous
                    demandez un permis PAP en dehors de la Bretagne, contactez le CRPMEM Bretagne.
                </InfoError>
                <InfoError>
                    <span>Besoin d&apos;un échéancier de paiement ?</span>
                    Contactez directement le CRPMEM Bretagne.
                </InfoError>
            </Section>
        </Main>
    );
}

export function CompanyProfile() {
    const { id: companyId } = useParams();
    const { data: company } = useGetCompanyQuery(companyId);
    const { data: user } = useCurrentUserQuery();
    const { data: bankInfos = [] } = useUserBankInfo(user ? user.id : 0);

    return (
        <Main>
            <TopBackButton />

            <Section>
                <Intro>
                    <UserInfo>
                        <ProfileName>{company ? company.name : '-'}</ProfileName>
                    </UserInfo>
                </Intro>

                <FormTitle>Informations générales</FormTitle>
                <Form>
                    <FormItem>
                        <Label>Nom :</Label>
                        <TextInput value={company ? company.name || '' : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Forme juridique :</Label>
                        <TextInput value={company ? formatCompanyType(company.type) : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Personne principale :</Label>
                        <TextInput value={formatCompanyMainUser(company)} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Identification :</Label>
                        <TextInput value={company ? company.registration || '' : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Identification 2 :</Label>
                        <TextInput value={company ? company.registration2 || '' : ''} readOnly />
                    </FormItem>
                </Form>

                <FormTitle>Informations de contact</FormTitle>
                <Form>
                    <FormItem>
                        <Label>Rue :</Label>
                        <TextInput value={company ? company.address1 || '' : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Rue (complément) :</Label>
                        <TextInput value={company ? company.address2 || '' : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Code postal :</Label>
                        <TextInput value={company ? company.zipCode || '' : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Ville :</Label>
                        <TextInput value={company ? company.city || '' : ''} readOnly />
                    </FormItem>

                    <FormItem>
                        <Label>Téléphone :</Label>
                        <TextInput value={company ? company.phoneNum || '' : ''} readOnly />
                    </FormItem>
                    <FormItem>
                        <Label>Email :</Label>
                        <TextInput value={company ? company.email || '' : ''} readOnly />
                    </FormItem>
                </Form>

                <LargeContainerHeader>Informations de paiement</LargeContainerHeader>
                <BankAccountContainer>
                    <BankInfoForm
                        user={user}
                        company={company}
                        bankInfo={bankInfos.find((info) => !!company && info.companyId === company.id)}
                    />
                </BankAccountContainer>

                <InfoError>
                    <span>Une erreur dans vos informations ?</span>
                    Contactez votre CDPMEM pour rectification. Si votre navire est immatriculé hors Bretagne ou si vous
                    demandez un permis PAP en dehors de la Bretagne, contactez le CRPMEM Bretagne.
                </InfoError>
                <InfoError>
                    <span>Besoin d&apos;un échéancier de paiement ?</span>
                    Contactez directement le CRPMEM Bretagne.
                </InfoError>
            </Section>
        </Main>
    );
}

function formatCompanyType(type) {
    switch (type) {
        case 'Copropriété':
            return 'Copropriété';
        case 'SARL':
            return 'SARL - Société à responsabilité limitée';
        case 'EURL':
            return 'EURL - Entreprise unipersonnelle à responsabilité limitée';
        case 'EI':
            return 'EI - Entreprise Individuelle (En nom propre)';
        case 'SELARL':
            return 'SELARL - Société d’exercice libéral à responsabilité limitée';
        case 'SA':
            return 'SA - Société anonyme';
        case 'SAS':
            return 'SAS - Société par action simplifiée';
        case 'SASU':
            return 'SASU - Société par action simplifiée unipersonnelle';
        case 'SNC':
            return 'SNC - Société en nom collectif';
        case 'SCP':
            return 'SCP - Société civile professionnelle';
        default:
            return 'Non renseigné';
    }
}

function formatCompanyMainUser(company) {
    if (!company) {
        return '';
    }

    if (company.mainUserFirstName && company.mainUserLastName) {
        return `${company.mainUserFirstName} ${company.mainUserLastName}`;
    } else if (company.mainUserFirstName) {
        return company.mainUserFirstName;
    } else if (company.mainUserLastName) {
        return company.mainUserLastName;
    } else {
        return '';
    }
}

const EMPTY_BANK_INFO = { iban: '', bic: '', agency: '' };
function BankInfoForm({ user, company, bankInfo = EMPTY_BANK_INFO }) {
    const mutation = useUserBankInfoMutation();

    const [iban, setIban] = useState('');
    const [bic, setBic] = useState('');
    const [agency, setAgency] = useState('');
    const [updatingBankInfo, setUpdatingBankInfo] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const isValid =
        iban.length > 0 &&
        isValidIBAN(electronicFormatIBAN(iban)) &&
        bic.length > 0 &&
        isValidBIC(bic) &&
        agency.length > 0;

    useEffect(() => {
        if (bankInfo) {
            setIban(friendlyFormatIBAN(bankInfo.iban));
            setBic(bankInfo.bic);
            setAgency(bankInfo.agency);
            setIsDirty(false);
        }
    }, [bankInfo]);

    const updateBankInfo = useCallback(
        (evt) => {
            evt.preventDefault();

            const data = new FormData(evt.currentTarget);
            const info = Object.fromEntries(data.entries());
            info.companyId = parseInt(info.companyId, 10);
            info.iban = info.iban.replaceAll(' ', '');
            info.bic = info.bic.replaceAll(' ', '');
            info.agency = info.agency.trim();

            setUpdatingBankInfo(true);
            mutation.mutate(
                { userId: user.id, info },
                {
                    onSuccess: () => {
                        setIsDirty(false);
                        setUpdatingBankInfo(false);
                    }
                }
            );
        },
        [user, mutation]
    );

    const showAlert = useAlertDialog();
    const dismissAlert = useDismissAlert();
    const printAuthorization = useCallback(
        (evt) => {
            evt.preventDefault();
            showAlert({
                title: 'Imprimer mandat',
                messages: [
                    'Afin de compléter votre demande de paiement par prélèvement, vous devez imprimer le mandat de prélèvement en deux exemplaires et les envoyer signés à votre CDPMEM et à vôtre banque. Cette autorisation est à réaliser une seule fois, puis en cas de changement de compte bancaire.'
                ],
                okCb: () => {
                    api.generateBankAuthorization(user.id, company ? company.id : undefined).then((res) => {
                        if (res.status === 'error') {
                            alert(`Erreur: ${res.errorMessage}\nInfo: ${res.devMessage}`);
                        } else {
                            downloadFileFromBase64Data(
                                res.data.data.notificationPDFData,
                                `autorisation_${user.firstName}_${user.lastName}${
                                    company ? `_${company.name}` : ''
                                }.pdf`,
                                'application/pdf'
                            );
                        }
                    });
                    dismissAlert();
                }
            });
        },
        [showAlert, dismissAlert, user]
    );

    return (
        <FormBankAccount onSubmit={updateBankInfo}>
            <input type="hidden" name="companyId" value={company ? company.id : ''} />
            <FormItem>
                <Label>Numéro IBAN :</Label>
                <TextInput
                    placeholder="FR00 0000 0000 0000 0000 0000 000"
                    name="iban"
                    value={iban}
                    onChange={(evt) => {
                        setIban(evt.target.value);
                        setIsDirty(true);
                    }}
                />
            </FormItem>
            <FormItem>
                <Label>Code BIC (ou SWIFT) :</Label>
                <TextInput
                    placeholder="AAAA-BB-CC-123"
                    name="bic"
                    value={bic}
                    onChange={(evt) => {
                        setBic(evt.target.value);
                        setIsDirty(true);
                    }}
                />
            </FormItem>
            <FormItem>
                <Label>Agence de domiciliation :</Label>
                <TextInput
                    name="agency"
                    value={agency}
                    onChange={(evt) => {
                        setAgency(evt.target.value);
                        setIsDirty(true);
                    }}
                />
            </FormItem>
            <FullWidth>
                <Button type="submit" disabled={!isDirty || (isDirty && !isValid) || updatingBankInfo}>
                    Sauver
                </Button>
                <Button onClick={printAuthorization} disabled={updatingBankInfo || !isValid || isDirty}>
                    Imprimer mandat
                </Button>
            </FullWidth>
        </FormBankAccount>
    );
}
