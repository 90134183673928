import React, { Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import { useGetCurrentPEPProxy, useGetPEPRequestHint } from '@SUPPORT/queries';
import { loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';

import { CancelButton, NextButton } from './common';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Section, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import {
    Check,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

export function RequestNature() {
    const history = useHistory();
    const { seasonality } = useParams();
    const [kind, setKind] = useState('');
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);
    const currentProxy = useGetCurrentPEPProxy(currentRequest);
    const { data: hint, isLoading: hintIsLoading } = useGetPEPRequestHint(
        currentRequest ? currentRequest.license : '',
        currentRequest && currentRequest.boat ? currentRequest.boat.telecapecheId : '',
        currentRequest && currentRequest.requestAuthor ? currentRequest.requestAuthor.telecapecheId : '',
        currentRequest && currentRequest.requestAuthor ? currentRequest.requestAuthor.companyTelecapecheId : ''
    );

    useEffect(() => {
        if (currentRequest) {
            const kind = currentRequest.isFirstInstallation
                ? 'isFirstInstallation'
                : currentRequest.isLicenseRenewal
                ? 'isLicenseRenewal'
                : currentRequest.isNewLicenseRequest
                ? 'isNewLicenseRequest'
                : '';
            setKind(kind);
        }
    }, [currentRequest]);

    const onBack = useCallback(() => {
        if (currentRequest) {
            delete currentRequest.isFirstInstallation;
            delete currentRequest.isLicenseRenewal;
            delete currentRequest.isNewLicenseRequest;
            saveTemporaryPEPRequest(currentRequest);
        }
    }, [currentRequest]);

    const next = useCallback(() => {
        if (currentRequest) {
            const license = currentProxy.seasons[0].license;
            if (!currentRequest.uniqueId) {
                currentRequest.isFirstInstallation = kind === 'isFirstInstallation';
                currentRequest.isLicenseRenewal = kind === 'isLicenseRenewal';
                currentRequest.isNewLicenseRequest = kind === 'isNewLicenseRequest';
                saveTemporaryPEPRequest(currentRequest);
            }

            if (
                license.additionalMetadata &&
                license.additionalMetadata.wizardInitialStepOptionAccessoryView &&
                (license.additionalMetadata.wizardInitialStepOptionAccessoryView.type !== 'popupOptionNbHommesABord' ||
                    (license.additionalMetadata.wizardInitialStepOptionAccessoryView.type ===
                        'popupOptionNbHommesABord' &&
                        currentRequest.boat.navigationCategory === 3))
            ) {
                history.push(`/request/pep/${seasonality}/options`);
            } else if (
                license.additionalMetadata &&
                license.additionalMetadata.modeLicenceExtraits === 'licenceXORextrait'
            ) {
                history.push(`/request/pep/${seasonality}/mode`);
            } else if (license.zones && license.zones.length > 0) {
                history.push(`/request/pep/${seasonality}/zones`);
            } else {
                history.push(`/request/pep/${seasonality}/admin`);
            }
        } else {
            alert('Erreur inconnue');
            history.push('/');
        }
    }, [currentRequest, currentProxy, kind, history, seasonality]);

    const disabled = (currentRequest && !!currentRequest.uniqueId) || hintIsLoading;
    const firstInstallationDisabled = hint && !hint.isFirstInstallation;
    const renewalDisabled = hint && !hint.isLicenseRenewal;
    const newLicenseDisabled = hint && !hint.isNewLicenseRequest;

    const now = new Date();
    const season = seasonality === 'annual' ? now.getFullYear() + 1 : `${now.getFullYear()}-${now.getFullYear() + 1}`;

    return (
        <Fragment>
            <Main>
                <TopBackButton onBack={onBack} />
                <Section>
                    <Intro>
                        <Title>Nature de votre demande de licences pour la pêche embarquée</Title>
                        <SubTitle>Campagne Pêche Embarquée {season}</SubTitle>
                    </Intro>

                    <BigButtonGrid withMargin>
                        <ItemSelectionWithCheck
                            itemSelected={kind === 'isFirstInstallation'}
                            onClick={
                                disabled || firstInstallationDisabled ? null : () => setKind('isFirstInstallation')
                            }
                            className={disabled || firstInstallationDisabled ? 'disabled' : null}
                        >
                            <Check />
                            <ItemSelectionTitle>1ère installation</ItemSelectionTitle>
                            <MainText>
                                Vous venez d&apos;acquérir votre premier navire et déposez une demande de licence pour
                                la première fois
                            </MainText>
                        </ItemSelectionWithCheck>
                        <ItemSelectionWithCheck
                            itemSelected={kind === 'isLicenseRenewal'}
                            onClick={disabled || renewalDisabled ? null : () => setKind('isLicenseRenewal')}
                            className={disabled || renewalDisabled ? 'disabled' : null}
                        >
                            <Check />
                            <ItemSelectionTitle>Renouvellement</ItemSelectionTitle>
                            <MainText>Vous déteniez déjà la licence lors de la campagne précédente</MainText>
                        </ItemSelectionWithCheck>
                        <ItemSelectionWithCheck
                            itemSelected={kind === 'isNewLicenseRequest'}
                            onClick={disabled || newLicenseDisabled ? null : () => setKind('isNewLicenseRequest')}
                            className={disabled || newLicenseDisabled ? 'disabled' : null}
                        >
                            <Check />
                            <ItemSelectionTitle>Nouvelle demande</ItemSelectionTitle>
                            <MainText>Vous ne déteniez pas cette licence lors de la campagne précédente</MainText>
                        </ItemSelectionWithCheck>
                    </BigButtonGrid>
                </Section>
            </Main>
            <Footer>
                <CancelButton />
                <NextButton primary disabled={kind === ''} onClick={next}>
                    Suivant
                </NextButton>
            </Footer>
        </Fragment>
    );
}
