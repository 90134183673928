import React from 'react';
import styled from 'styled-components';

import { getRequestTypeLabel } from '@STORES/common/pescalice';

import { formatLocaleDate } from '@SUPPORT/utils';
import { useCurrentUserQuery, useCurrentPAPRequest, useGetPAPSeason } from '@SUPPORT/queries';

import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import { LargeContainerTitle, LargeContainerContent } from '@COMPONENTS/licensee/common/LargeContainer';

const Content = styled(LargeContainerContent)`
    padding-top: 0;
    margin-bottom: 1rem;
`;

const RecapList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.8rem;
    margin-top: 0.8rem;
`;

const RecapListItem = styled.li`
    font-size: 1.4rem;
    color: black;
    font-weight: 600;
`;

const RecapListItemBullet = styled(RecapListItem)`
    list-style: disc;
    list-style-position: inside;
    color: #212121;
    font-weight: 400;
`;

const RecapListItemWithPrice = styled.li`
    padding: 0.7rem 1rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    color: black;
    font-weight: 500;
    background: rgba(224, 224, 224, 0.4);
`;

const Price = styled.h4`
    display: block;
    font-size: 1.5rem;
    color: var(--dark-blue);
    font-weight: 600;
`;

const Localization = styled.h3`
    display: block;
    margin: 0.4rem 0;
    font-size: 1.5rem;
    color: var(--light-blue);
    font-weight: 600;
`;

export function RequestRecapPAP() {
    const { data: user } = useCurrentUserQuery();
    const { data: currentRequest } = useCurrentPAPRequest(user ? user.id : null);
    const season = useGetPAPSeason(currentRequest ? currentRequest.season : null);

    const seasonName = currentRequest ? ` ${currentRequest.referenceYear - 1}-${currentRequest.referenceYear}` : '';
    const licenseRequestNature = getRequestTypeLabel(currentRequest || {}, false).toLowerCase();

    let recap = [];
    if (currentRequest && season) {
        const locs = new Map();
        currentRequest.extractRequests.forEach((extract) => {
            const fullExtract = season.extracts.find((ex) => ex.uniqueId === extract.targetedExtract);
            if (!locs.has(fullExtract.administrativeDivisionName)) {
                locs.set(fullExtract.administrativeDivisionName, []);
            }

            locs.get(fullExtract.administrativeDivisionName).push(
                <RecapListItemWithPrice>
                    {fullExtract.name} (en {getRequestTypeLabel(extract, false).toLowerCase()})
                    <Price>Paiement de {fullExtract.basePrice}€</Price>
                </RecapListItemWithPrice>
            );
        });

        for (let [loc, extracts] of locs) {
            recap.push(<Localization>{loc}</Localization>);
            recap = recap.concat(extracts);
        }
    }

    const currentYear = new Date().getFullYear().toString();
    const userEmail = user ? user.email : '?';

    return (
        <Main>
            <TopBackButton target="/" replace />

            {currentRequest && (
                <Intro>
                    <Title>Récapitulatif</Title>
                    <SubTitle>
                        Vous avez déposé ou modifié une demande de licence de pêche à pied pour la campagne
                        {seasonName}, le {formatLocaleDate(currentRequest.depositDate, 'dd/MM/yyyy')} à
                        {formatLocaleDate(currentRequest.depositDate, ' kk:mm')}.
                    </SubTitle>
                    <SubTitle>
                        Un email de confirmation vient de vous être envoyé à l&apos;adresse{' '}
                        <a href={`mailto:${userEmail}`} style={{ fontSize: '1.5rem' }}>
                            {userEmail}
                        </a>
                        . Merci de vérifier vos spams.
                    </SubTitle>
                    <SubTitle>
                        Voici le résumé des éléments de cette demande ainsi que les montants des paiements
                        (automatiquement débités si vous avez autorisé les prélèvements) :
                    </SubTitle>
                    <LargeContainerTitle>Licence</LargeContainerTitle>
                    <Content>
                        <RecapList>
                            <RecapListItemWithPrice>
                                Pêche à pied - Saison {seasonName} (en {licenseRequestNature})
                                <Price>Paiement de {currentRequest.basePrice}€</Price>
                            </RecapListItemWithPrice>
                        </RecapList>
                    </Content>
                    <LargeContainerTitle>Timbres</LargeContainerTitle>
                    <Content>
                        <RecapList>{recap}</RecapList>
                    </Content>
                    <LargeContainerTitle>Pièces à fournir</LargeContainerTitle>
                    <Content>
                        <RecapList>
                            <RecapListItem>
                                Vous devrez aussi faire parvenir à votre CDPM les pièces administratives suivantes :
                            </RecapListItem>
                            <RecapListItemBullet>
                                Votre justificatif de demande de permis de pêche à pied.
                            </RecapListItemBullet>
                            <RecapListItemBullet>
                                Une photocopie de l&apos;attestation d&apos;affiliation au régime MSA ou ENIM.
                            </RecapListItemBullet>
                            <RecapListItemBullet>
                                Une photo d&apos;identité récente en cas de première installation ou de nouvelle
                                demande.
                            </RecapListItemBullet>
                            <RecapListItem>
                                Cette demande est modifiable en ligne du 01/01/{currentYear} au 31/01/{currentYear}. Au
                                delà de cette date, la demande est définitive et ne peut plus être annulée.
                            </RecapListItem>
                            <RecapListItem>
                                Vous pouvez contacter votre CDPM pour toute difficulté concernant cette demande.
                            </RecapListItem>
                        </RecapList>
                    </Content>
                </Intro>
            )}
        </Main>
    );
}
