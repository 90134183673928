import '@CSS/reset.css';

import React from 'react';
import Rollbar from 'rollbar';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import { api } from '@SUPPORT/api';
import { initializeLocale } from '@SUPPORT/i18n';
import { configureRollbar } from '@SUPPORT/rollbar';
configureRollbar();

import { LicenseeRoot } from '@COMPONENTS/licensee/Root';

initializeLocale('fr', 'EUR');

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            onError: (err) => Rollbar.error(err.message, err)
        },
        mutations: {
            onError: (err, vars) => Rollbar.error(err.message, err, vars)
        }
    }
});

queryClient
    .prefetchQuery('admin-division', () => api.listAdministrativeDivisions().then((response) => response.data))
    .then(() => {
        const root = document.getElementById('root');
        ReactDOM.render(
            <BrowserRouter basename="/licensee">
                <StyleSheetManager disableVendorPrefixes>
                    <QueryClientProvider client={queryClient}>
                        <Route component={LicenseeRoot} />
                    </QueryClientProvider>
                </StyleSheetManager>
            </BrowserRouter>,
            root
        );
    });
