import React, { Fragment, useEffect, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';

import { useDismissModalDialog, useModalDialogState, useOpenModalDialog } from '@STORES/licensee';
import {
    useGetPEPProxiesList,
    useGetAdministrativeDivision,
    useGetAdministrativeDivisionsList,
    useGetCurrentPEPProxy,
    useCurrentUserQuery,
    useGetUserPEPRequests
} from '@SUPPORT/queries';
import { loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';

import { CancelButton, NextButton } from './common';

import { TextInput } from '@COMPONENTS/licensee/common/TextInput';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Section, Intro, Title, SubTitle, MainText } from '@COMPONENTS/licensee/common/Main';
import MagnificationGlass from '@GRAPHICS/licensee/magnificationglass.svg';
import { Overlay, DialogBox, ModalTitle, ExitZone, Content } from '@COMPONENTS/licensee/common/Modal';
import {
    Check,
    Info,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';
import { Button } from '@COMPONENTS/licensee/common/Button';
import { TableTwoColumns } from '@COMPONENTS/licensee/common/TableTwoColumns';

const Search = styled.div`
    position: relative;
`;

const SearchInput = styled(TextInput)`
    padding-left: 3.2rem;
`;

const SearchIcon = styled(MagnificationGlass)`
    --logo-width: 2.6rem;
    position: absolute;
    top: 0.6rem;
    left: 0.5rem;
    z-index: 1;
    width: var(--logo-width);
    aspect-ratio: 1;
    color: rgb(190, 190, 190);
`;

const Place = styled(MainText)`
    margin-bottom: 0.8rem;
`;

const AdditionalInfo = styled(MainText)`
    font-size: 1.4rem;
    font-style: italic;
`;

const RecapListTitle = styled.h4`
    margin-bottom: 1rem;
    font-size: 1.6rem;
    color: var(--dark-blue);
    font-weight: 800;
    font-family: var(--font-family);
`;

const RecapListSubtitle = styled.h5`
    margin-bottom: 0.2rem;
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
    color: var(--light-blue);
    font-family: var(--font-family);
`;

export function RequestLicense() {
    const history = useHistory();
    const { seasonality } = useParams();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);

    const { data: adminDivisions = [] } = useGetAdministrativeDivisionsList();
    const { data: proxies = [], isLoading: isLoadingProxies } = useGetPEPProxiesList();

    const baseYear = new Date().getFullYear();
    const { data: user } = useCurrentUserQuery();
    const { data: requests = [], isLoading: isLoadingRequests } = useGetUserPEPRequests(
        user ? user.id : null,
        baseYear + 1,
        seasonality
    );

    // proxies.forEach((proxy) => {
    //     if (proxy.seasons[0].license.additionalMetadata) {
    //         console.log(proxy.name, proxy.seasons[0].license.additionalMetadata);
    //     }
    // });

    const [filter, setFilter] = useState('');
    const filteredProxies = proxies.filter((proxy) => {
        const season = proxy.seasons[0];
        if (season && season.seasonality !== seasonality) {
            return false;
        }

        if (filter !== '' && !proxy.name.toLowerCase().includes(filter.toLowerCase())) {
            return false;
        }

        const filterAsNum = Number(filter);
        if (!isNaN(filterAsNum)) {
            const div = adminDivisions.find((div) => div.administrativeDivisionPath === proxy.administrativeDivision);
            return div && div.zipCode !== filterAsNum;
        }

        return true;
    });

    const [selection, setSelection] = useState(null);

    const currentProxy = useGetCurrentPEPProxy(currentRequest);
    useEffect(() => {
        if (currentProxy) {
            setSelection(currentProxy);
        }
    }, [currentProxy]);

    const onBack = useCallback(() => {
        if (currentRequest) {
            delete currentRequest.targetedZones;
            delete currentRequest.obtainedZones;
            delete currentRequest.additionalMetadata;
            delete currentRequest.divers;
            delete currentRequest.license;
            saveTemporaryPEPRequest(currentRequest);
        }
    }, [currentRequest]);

    const next = useCallback(() => {
        if (currentRequest) {
            if (!currentRequest.uniqueId) {
                delete currentRequest.targetedZones;
                delete currentRequest.obtainedZones;
                delete currentRequest.additionalMetadata;
                delete currentRequest.divers;
                currentRequest.license = selection.seasons[0].license.uniqueId;
                saveTemporaryPEPRequest(currentRequest);
            }
            history.push(`/request/pep/${seasonality}/nature`);
        } else {
            alert('Erreur inconnue');
            history.push('/');
        }
    }, [currentRequest, history, selection, seasonality]);

    return (
        <Fragment>
            <Main>
                <TopBackButton onBack={onBack} />
                <Section>
                    <Intro>
                        <Title>Selectionner la licence</Title>
                        <SubTitle>
                            Sélectionnez la licence que vous souhaitez. S&apos;il s&apos;agit du même navire, chaque
                            licence doit faire l&apos;objet d&apos;une demande distincte.
                        </SubTitle>
                    </Intro>

                    <BigButtonGrid withMargin>
                        <Search>
                            <SearchInput value={filter} onChange={(evt) => setFilter(evt.target.value)} />
                            <SearchIcon />
                        </Search>
                    </BigButtonGrid>
                    <BigButtonGrid withMargin>
                        {!isLoadingProxies &&
                            !isLoadingRequests &&
                            filteredProxies.map((proxy) => (
                                <LicenseButton
                                    request={currentRequest}
                                    proxy={proxy}
                                    isSelected={selection === proxy}
                                    isDisabled={
                                        !!currentRequest &&
                                        !!requests.find(
                                            (req) =>
                                                req.license.pescaliceIdentifier === proxy.pescaliceIdentifier &&
                                                req.requestAuthor.companyTelecapecheId ===
                                                    currentRequest.requestAuthor.companyTelecapecheId &&
                                                req.boat.telecapecheId === currentRequest.boat.telecapecheId
                                        ) &&
                                        proxy.pescaliceIdentifier !==
                                            'pescalice.peche-embarquee.license.bretagne.ormeaux-plongee' && // This fucking license is a special case and is allowed to be requested multiple times
                                        proxy.pescaliceIdentifier !== 'pescalice.peche-embarquee.license.56.seiche' // This fucking license is a special case and is allowed to be requested multiple times
                                    }
                                    onClick={() => setSelection(proxy)}
                                    key={proxy.pescaliceIdentifier}
                                />
                            ))}
                    </BigButtonGrid>
                </Section>
            </Main>
            <Footer>
                <CancelButton />
                <NextButton primary disabled={!selection} onClick={next}>
                    Suivant
                </NextButton>
            </Footer>

            <LicenseInfoDialog />
        </Fragment>
    );
}

const LicenseButton = ({ request, proxy, isSelected, isDisabled, onClick }) => {
    const adminDiv = useGetAdministrativeDivision(proxy.administrativeDivision);

    const openModalDialog = useOpenModalDialog();
    const showProxyInfo = useCallback(
        (evt) => {
            evt.stopPropagation();
            openModalDialog('PEP-PROXY-INFO', proxy);
        },
        [proxy, openModalDialog]
    );

    const disabled = (request && !!request.uniqueId) || isDisabled;
    const season = proxy.seasons[0];
    const meta = season.license.additionalMetadata;

    return (
        <ItemSelectionWithCheck
            itemSelected={isSelected}
            onClick={disabled ? null : () => onClick(proxy)}
            className={disabled ? 'disabled' : null}
        >
            <Check />
            <ItemSelectionTitle>{proxy.name}</ItemSelectionTitle>
            <Place>{adminDiv ? adminDiv.name : ''}</Place>
            {meta && meta.preambule && <AdditionalInfo>{meta.preambule}</AdditionalInfo>}
            <Info onClick={showProxyInfo} />
        </ItemSelectionWithCheck>
    );
};

const LicenseInfoDialog = () => {
    const { key, data: proxy } = useModalDialogState();
    const dismiss = useDismissModalDialog();

    return (
        <Overlay show={key === 'PEP-PROXY-INFO'}>
            {proxy && (
                <DialogBox>
                    <ModalTitle>Information</ModalTitle>
                    <Content>
                        <RecapListTitle>{proxy.name}</RecapListTitle>
                        <TableTwoColumns>
                            <tbody>
                                <tr>
                                    <td>Délibération cadre</td>
                                    <td className="bold">2021-025</td>
                                </tr>
                                <tr>
                                    <td>Date de délibération cadre</td>
                                    <td className="bold">18/03/2016</td>
                                </tr>
                                <tr>
                                    <td>Délibération conditions particulières d&aposaccès</td>
                                    <td className="bold">2018-058</td>
                                </tr>
                                <tr>
                                    <td>Date de délibération CPA</td>
                                    <td className="bold">21/09/2018</td>
                                </tr>
                            </tbody>
                        </TableTwoColumns>

                        <RecapListSubtitle>Ouverture</RecapListSubtitle>
                        <TableTwoColumns>
                            <tbody>
                                <tr>
                                    <td>Du</td>
                                    <td className="bold">01/01/2022</td>
                                </tr>
                                <tr>
                                    <td>Au</td>
                                    <td className="bold">31/12/2022</td>
                                </tr>
                            </tbody>
                        </TableTwoColumns>

                        <RecapListSubtitle>Période de demandes</RecapListSubtitle>
                        <TableTwoColumns>
                            <tbody>
                                <tr>
                                    <td>Du</td>
                                    <td className="bold">01/09/2021</td>
                                </tr>
                                <tr>
                                    <td>Au</td>
                                    <td className="bold">30/09/2021</td>
                                </tr>
                            </tbody>
                        </TableTwoColumns>
                    </Content>

                    <ExitZone>
                        <Button onClick={() => dismiss()}>Ok</Button>
                    </ExitZone>
                </DialogBox>
            )}
        </Overlay>
    );
};
