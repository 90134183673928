import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';

import { useCurrentUserQuery, useCurrentUserCompaniesListQuery } from '@SUPPORT/queries';
import { userFullName, loadTemporaryPEPRequest, saveTemporaryPEPRequest } from '@SUPPORT/utils';

import { CancelButton, NextButton } from './common';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Section, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import {
    Check,
    BigButtonGrid,
    ItemSelectionWithCheck,
    ItemSelectionTitle
} from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

const Requester = styled.strong`
    color: black;
    font-weight: 700;
`;

export function RequestCompany() {
    const history = useHistory();
    const { seasonality } = useParams();
    const { data: user } = useCurrentUserQuery();
    const { data: companies = [] } = useCurrentUserCompaniesListQuery();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);

    const [selectedCompany, selectCompany] = useState('');
    useEffect(() => {
        if (companies.length === 0) {
            return;
        }

        if (currentRequest) {
            const company = companies.find(
                (company) => company.id === currentRequest.requestAuthor.companyTelecapecheId
            );
            if (company) {
                selectCompany(company.id);
            }
        }
    }, [currentRequest, companies]);

    const onBack = useCallback(() => {
        if (currentRequest) {
            delete currentRequest.requestAuthor.companyTelecapecheId;
            saveTemporaryPEPRequest(currentRequest);
        }
    }, [currentRequest]);

    const next = useCallback(() => {
        if (currentRequest) {
            currentRequest.requestAuthor.companyTelecapecheId = selectedCompany;
            saveTemporaryPEPRequest(currentRequest);
        }

        history.push(`/request/pep/${seasonality}/boat`);
    }, [selectedCompany, currentRequest, history, seasonality]);

    return (
        <Fragment>
            <Main>
                <TopBackButton onBack={onBack} />
                <Section>
                    <Intro>
                        <Title>Choix de l&apos;entreprise</Title>
                        <SubTitle>
                            <Requester></Requester>
                        </SubTitle>
                        <SubTitle>
                            Veuillez sélectionner l&apos;entreprise pour laquelle vous souhaitez déposer une demande de
                            licence en Pêche embarquée.
                        </SubTitle>
                    </Intro>

                    <Fragment>
                        <BigButtonGrid withMargin>
                            {companies.map((company) => (
                                <ItemSelectionWithCheck
                                    itemSelected={selectedCompany === company.id}
                                    onClick={
                                        currentRequest && !!currentRequest.uniqueId
                                            ? null
                                            : () => selectCompany(company.id)
                                    }
                                    className={currentRequest && !!currentRequest.uniqueId ? 'disabled' : null}
                                    key={company.id}
                                >
                                    <Check />
                                    <ItemSelectionTitle>{company.name}</ItemSelectionTitle>
                                </ItemSelectionWithCheck>
                            ))}
                            <ItemSelectionWithCheck
                                itemSelected={selectedCompany === null}
                                onClick={currentRequest && !!currentRequest.uniqueId ? null : () => selectCompany(null)}
                                className={currentRequest && !!currentRequest.uniqueId ? 'disabled' : null}
                            >
                                <Check />
                                <ItemSelectionTitle>Sans Entreprise (en nom propre)</ItemSelectionTitle>
                            </ItemSelectionWithCheck>
                        </BigButtonGrid>
                    </Fragment>
                </Section>
            </Main>
            <Footer>
                <CancelButton />
                <NextButton primary onClick={next} disabled={selectedCompany === ''}>
                    Suivant
                </NextButton>
            </Footer>
        </Fragment>
    );
}
