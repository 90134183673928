import React, { useState } from 'react';
import styled from 'styled-components';

import { useModalDialogState, useDismissModalDialog } from '@STORES/licensee';

import { Chevron } from '@COMPONENTS/licensee/common/BigButton';
import { Overlay, DialogBox } from '@COMPONENTS/licensee/common/Modal';

import ExitIcon from '@GRAPHICS/licensee/xmark.svg';
import Graphic01 from '@GRAPHICS/licensee/payment-process-01.svg';
import Graphic02 from '@GRAPHICS/licensee/payment-process-02.svg';
import Graphic03 from '@GRAPHICS/licensee/payment-process-03.svg';
import Graphic04 from '@GRAPHICS/licensee/payment-process-04.svg';
import Graphic05 from '@GRAPHICS/licensee/payment-process-05.svg';
import Graphic06 from '@GRAPHICS/licensee/payment-process-06.svg';

const DialogBoxForWizard = styled(DialogBox)`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 340px;
    max-width: 96%;
    height: 360px;
    margin-top: 40px;
    background: #e8f9ff;
`;

const ChevronWizard = styled(Chevron)`
    position: absolute;
    top: 150px;
    left: ${({ $leading, disabled }) => ($leading && disabled ? '-20px' : $leading ? '15px' : 'auto')};
    right: ${({ $leading, disabled }) => ($leading ? 'auto' : disabled ? '-20px' : '15px')};
    rotate: ${({ $leading }) => ($leading ? '180deg' : '0')};
    z-index: 2;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: left 0.3s cubic-bezier(0.62, 0.16, 0.13, 1.01), right 0.3s cubic-bezier(0.62, 0.16, 0.13, 1.01);
`;

const Item = styled.div`
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    left: ${({ left, right }) => (left ? '-100%' : right ? '100%' : '0')};
    transition: left 0.3s cubic-bezier(0.62, 0.16, 0.13, 1.01);
`;

const IntroWizard = styled.div`
    padding: 25px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    color: #4d8fac;
    text-align: center;
    line-height: 1.2;

    & h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: inherit;
    }
`;

const Text = styled.h3`
    position: absolute;
    inset: auto 20px 26px 20px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    color: #4d8fac;
    text-align: center;
    line-height: 1.2;
`;

const ExitXmark = styled(ExitIcon)`
    position: absolute;
    top: 25px;
    left: calc(50% - 10px);
    width: 20px;
    aspect-ratio: 1;
    color: var(--dark-blue);
    cursor: pointer;
    z-index: 1;
`;

const Graphic01Formated = styled(Graphic01)`
    position: absolute;
    top: 80px;
    width: 220px;
`;

const Graphic02Formated = styled(Graphic02)`
    position: absolute;
    top: 190px;
    width: 320px;
    top: ${({ $isActive }) => ($isActive ? '190px' : '200px')};
    width: 310px;
    scale: ${({ $isActive }) => ($isActive ? '1' : '0.7')};
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
`;

const Graphic03Formated = styled(Graphic03)`
    position: absolute;
    top: 70px;
    width: 200px;
`;

const Graphic04Formated = styled(Graphic04)`
    position: absolute;
    top: 65px;
    width: 170px;
`;

const Graphic05Formated = styled(Graphic05)`
    position: absolute;
    top: ${({ $isActive }) => ($isActive ? '170px' : '160px')};
    width: 250px;
    scale: ${({ $isActive }) => ($isActive ? '1' : '0.7')};
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
`;

const Graphic06Formated = styled(Graphic06)`
    position: absolute;
    top: 75px;
    width: 215px;
`;

export function PaymentInfoDialog() {
    const { key } = useModalDialogState();
    const dismiss = useDismissModalDialog();

    const [activeIndex, setActiveIndex] = useState(1);

    return (
        <Overlay show={key === 'PAYMENT-INFO'}>
            <DialogBoxForWizard>
                <ExitXmark onClick={() => dismiss()} />

                <Item isActive={activeIndex === 1} left={activeIndex >= 2} right={activeIndex === 0}>
                    <IntroWizard>
                        <h2>
                            Avec Pescalice,
                            <br />
                            ne plus payer par chèque,
                            <br />
                            c'est maintenant possible !
                        </h2>
                        <p>
                            Découvrez comment payer vos futures licences par simple prélèvement,
                            <br />
                            en 4 étapes...
                        </p>
                    </IntroWizard>
                </Item>
                <Item isActive={activeIndex === 2} left={activeIndex >= 3} right={activeIndex <= 1}>
                    <Graphic01Formated />
                    <Graphic02Formated $isActive={activeIndex === 2} />
                    <Text>Dans la section "Vos infos personnelles", remplissez vos coordonnées bancaires.</Text>
                </Item>
                <Item isActive={activeIndex === 3} left={activeIndex >= 4} right={activeIndex <= 2}>
                    <Graphic03Formated />
                    <Text>Cliquez sur le bouton "Imprimer mandat" et imprimez le document en 2 exemplaires.</Text>
                </Item>
                <Item isActive={activeIndex === 4} left={activeIndex >= 5} right={activeIndex <= 3}>
                    <Graphic04Formated />
                    <Graphic05Formated $isActive={activeIndex === 4} />
                    <Text>Ajoutez le lieu puis votre signature sur chacun des documents.</Text>
                </Item>
                <Item isActive={activeIndex === 5} left={activeIndex >= 6} right={activeIndex <= 4}>
                    <Graphic06Formated />
                    <Text>Envoyez le mandat signé, par courrier, à votre CDPMEM + votre banque.</Text>
                </Item>

                <ChevronWizard
                    $leading={true}
                    disabled={activeIndex === 1}
                    onClick={() => setActiveIndex((idx) => (idx > 0 ? idx - 1 : idx))}
                />
                <ChevronWizard
                    disabled={activeIndex === 5}
                    onClick={() => setActiveIndex((idx) => (idx < 5 ? idx + 1 : idx))}
                />
            </DialogBoxForWizard>
        </Overlay>
    );
}
