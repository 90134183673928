import styled from 'styled-components';

export const TextInput = styled.input.attrs({ type: 'text' })`
    position: relative;
    z-index: 1;
    height: 3.6rem;
    width: 100%;
    padding: 1.2rem 0.5rem;
    font: var(--font-form-text);
    color: var(--form-elements-text-color);
    border: 0.2rem solid #cacaca;
    border-radius: var(--form-elements-border-radius);
    box-sizing: border-box;
    background: var(--form-elements-bkd-color);
    transition: all 0.3s ease;

    &[disabled] {
        color: var(--form-elements-text-color);
        border-color: var(--form-elements-bkd-color-readonly);
        background: var(--form-elements-bkd-color-readonly);
    }

    &[readOnly] {
        border-color: var(--form-elements-bkd-color-readonly);
        background: var(--form-elements-bkd-color-readonly);
    }

    @media (max-width: 600px) {
        & {
            max-width: calc(100% - var(--standard-margin));
        }
    }
`;
