import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useDismissAlert, useModalDialogState } from '@STORES/licensee';

import { Button } from './Button';

export const ExitZone = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 0.6rem;
    flex-shrink: 0;
    width: 100%;
    padding: calc(var(--standard-margin) * 0.8) calc(var(--standard-margin) * 1.4);
    border-top: solid 0.1rem var(--super-light-gray);

    @media (max-width: 812px) {
        & {
            transition: inset-inline-start 0.35s var(--ease-out-cubic);
        }
    }

    @media (max-width: 470px) {
        & {
            flex-direction: column;
        }
    }
`;

export const ModalTitle = styled.b`
    position: relative;
    display: block;
    flex-shrink: 0;
    width: calc(100% - var(--padding-modal) * 1.6);
    padding: calc(var(--standard-margin) * 0.8) calc(var(--standard-margin) * 1.4);
    font-size: calc(var(--global-text-size) * 1.4);
    color: var(--title-color);
    font: var(--font-title-normal);
    font-weight: 700;
    color: ${({ alert }) => (alert ? '#B20E0E' : 'var(--dark-blue)')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background: ${({ alert }) => (alert ? '#f9d3d3' : 'var(--super-light-blue)')};
`;

export const Content = styled.section`
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex: 1;
    flex-direction: column;
    /* justify-content: center; */
    padding: calc(var(--standard-margin) * 0.8) calc(var(--standard-margin) * 1.4);
    overflow-y: auto;

    & p {
        font: var(--font-form-text);
        margin-bottom: 0.5rem;
    }
`;

export const DialogBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 42rem;
    max-width: 90%;
    min-height: 25rem;
    max-height: 90%;
    margin-inline-start: auto;
    margin-inline-end: auto;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 1.1rem;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11), 0 4px 4px rgba(0, 0, 0, 0.11),
        0 8px 8px rgba(0, 0, 0, 0.11), 0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
    background: var(--main-background);

    @media (max-width: 812px) {
        & {
            max-width: 90%;
            border-radius: 0.6rem;
        }
    }
`;

export const Overlay = styled.div`
    position: fixed;
    inset: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    opacity: ${({ show }) => (show ? 1 : 0)};
    pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
    transition: opacity 0.15s var(--ease-out-cubic);

    ${DialogBox} {
        transform: ${({ show }) => (show ? 'translateY(0) scale(1)' : 'translateY(-3.5rem) scale(.7)')};
        transition: all 0.15s var(--ease-out-cubic);
    }
`;

export function AlertDialog() {
    const { key, data } = useModalDialogState();
    const show = key === 'ALERT';

    const dismissAlert = useDismissAlert();
    const dismiss = useCallback(() => dismissAlert(), [dismissAlert]);
    const confirm = useCallback(() => {
        if (data.okCb) {
            data.okCb();
        } else {
            dismissAlert();
        }
    }, [data, dismissAlert]);

    return (
        <Overlay show={show}>
            <DialogBox>
                <ModalTitle>{show && data ? data.title : ''}</ModalTitle>
                <Content>
                    {show && data ? data.messages.map((message, index) => <p key={index}>{message}</p>) : null}
                </Content>
                <ExitZone>
                    {show && data && data.hasDismiss && (
                        <Button onClick={dismiss}>{show && data ? data.dismissLabel : 'Annuler'}</Button>
                    )}
                    <Button primary={data && data.okIsPrimary} onClick={confirm}>
                        {show && data ? data.okLabel : 'Ok'}
                    </Button>
                </ExitZone>
            </DialogBox>
        </Overlay>
    );
}
