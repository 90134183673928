import React, { Fragment, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';

import { getOptionStatusLabel } from '@STORES/common/pescalice';

import {
    useCurrentUserQuery,
    useCurrentUserCompaniesListQuery,
    useGetCompanyQuery,
    useGetBoatQuery,
    useGetCurrentPEPProxy
} from '@SUPPORT/queries';
import {
    userFullName,
    companyFullName,
    formatLocaleDate,
    loadTemporaryPEPRequest,
    clearTemporaryPEPRequest,
    saveTemporaryPEPRequest
} from '@SUPPORT/utils';

import { NextButton, pricingsDetail } from './common';
import { TopBackButton } from '@COMPONENTS/licensee/common/TopBackButton';
import { Main, Intro, Title, SubTitle } from '@COMPONENTS/licensee/common/Main';
import { LargeContainerTitle, LargeContainerContent } from '@COMPONENTS/licensee/common/LargeContainer';
import { BigButtonGrid, ItemSelectionTitle, ItemSelection, Chevron } from '@COMPONENTS/licensee/common/BigButton';
import { Footer } from '@COMPONENTS/licensee/common/Footer';

const Content = styled(LargeContainerContent)`
    padding-top: 0;
    margin-bottom: 1rem;
`;

const RecapList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.8rem;
    margin-top: 0.8rem;
`;

const RecapListItem = styled.li`
    font-size: 1.4rem;
    color: black;
    font-weight: 600;
`;

const RecapListItemBullet = styled(RecapListItem)`
    list-style: disc;
    list-style-position: inside;
    color: #212121;
    font-weight: 400;
`;

const RecapListItemWithPrice = styled.li`
    padding: 0.7rem 1rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    color: black;
    font-weight: 500;
    background: rgba(224, 224, 224, 0.4);
`;

const Price = styled.h4`
    display: block;
    font-size: 1.5rem;
    color: var(--dark-blue);
    font-weight: 600;
`;

const BigButtonGridSpecial = styled(BigButtonGrid)`
    direction: rtl;

    & > button {
        justify-content: center;
        background: var(--super-light-blue);
        border-color: hsl(198, 72%, 85%);
    }
`;

export function RequestRecapPEP() {
    const history = useHistory();
    const { seasonality } = useParams();

    const { data: user } = useCurrentUserQuery();
    const currentRequest = useMemo(() => loadTemporaryPEPRequest(), []);
    const { data: boat } = useGetBoatQuery(
        currentRequest && currentRequest.boat ? currentRequest.boat.telecapecheId : null
    );
    const { data: company } = useGetCompanyQuery(
        currentRequest && currentRequest.requestAuthor ? currentRequest.requestAuthor.companyTelecapecheId : null
    );
    const { data: companies = [] } = useCurrentUserCompaniesListQuery();

    const currentProxy = useGetCurrentPEPProxy(currentRequest);
    const license = useMemo(() => (currentProxy ? currentProxy.seasons[0].license : {}), [currentProxy]);
    const metaData = useMemo(() => (license ? license.additionalMetadata : {}), [license]);

    const withStdOption =
        !!metaData &&
        !!metaData.wizardInitialStepOptionAccessoryView &&
        (metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionPlongee' ||
            metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionSpecies' ||
            metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionSeiche' ||
            metaData.wizardInitialStepOptionAccessoryView.type === 'popupOptionNbDrague');

    const withDivers =
        currentProxy &&
        (currentProxy.option === 'plongee' ||
            (metaData &&
                metaData.wizardInitialStepOptionAccessoryView &&
                metaData.wizardInitialStepOptionAccessoryView.type === 'diverRows'));

    const withZones = license && license.zones && license.zones.length > 0;

    const newRequestWithSameBoat = useCallback(() => {
        if (!currentRequest) {
            alert('Erreur inconnue');
            history.push('/');
            return;
        }

        const newRequest = {
            depositDate: new Date(),
            requestAuthor: {
                telecapecheId: currentRequest.requestAuthor.telecapecheId,
                companyTelecapecheId: currentRequest.requestAuthor.companyTelecapecheId
            },
            boat: {
                telecapecheId: currentRequest.boat.telecapecheId,
                name: boat.name,
                administrativeDivisionPath: currentRequest.boat.administrativeDivisionPath,
                length: currentRequest.boat.length,
                motorPower: currentRequest.boat.motorPower,
                registration: currentRequest.boat.registration,
                registrationDistrictCode: currentRequest.boat.registrationDistrictCode,
                navigationCategory: currentRequest.boat.navigationCategory
            }
        };

        clearTemporaryPEPRequest();
        saveTemporaryPEPRequest(newRequest);
        history.push(`/request/pep/${seasonality}/license`);
    }, [history, currentRequest, boat, seasonality]);

    const newRequestWithDifferentCompanyOrBoat = useCallback(() => {
        clearTemporaryPEPRequest();
        saveTemporaryPEPRequest({
            depositDate: new Date(),
            requestAuthor: {
                telecapecheId: user.id,
                companyTelecapecheId: null
            }
        });

        if (companies.length > 0) {
            history.push(`/request/pep/${seasonality}/company`);
        } else {
            history.push(`/request/pep/${seasonality}/boat`);
        }
    }, [history, seasonality, user, companies.length]);

    const finish = useCallback(() => {
        clearTemporaryPEPRequest();
        history.replace('/');
    }, [history]);

    const now = new Date();
    const season = seasonality === 'annual' ? now.getFullYear() + 1 : `${now.getFullYear()}-${now.getFullYear() + 1}`;

    return (
        <Fragment>
            <Main>
                <TopBackButton target="/" replace />
                <Intro>
                    <Title>Récapitulatif</Title>
                    <SubTitle>
                        Vous avez déposé une demande au nom de{' '}
                        {company ? companyFullName(company) : user ? userFullName(user, false) : ''} avec le navire{' '}
                        {currentRequest && currentRequest.boat && boat
                            ? `${boat.name} (${currentRequest.boat.registration})`
                            : ''}{' '}
                        pour la licence &quot;
                        {currentProxy ? currentProxy.name : ''}&quot; pour la campagne {season}, le{' '}
                        {formatLocaleDate(currentRequest ? currentRequest.depositDate : now, 'dd/MM/yyyy')} à
                        {formatLocaleDate(currentRequest ? currentRequest.depositDate : now, ' kk:mm')}.
                    </SubTitle>

                    {currentRequest && withStdOption && (
                        <Fragment>
                            <LargeContainerTitle>Options</LargeContainerTitle>
                            <Content>
                                <RecapList>
                                    <RecapListItemBullet>
                                        {getOptionStatusLabel(currentRequest.additionalMetadata.option)}
                                    </RecapListItemBullet>
                                </RecapList>
                            </Content>
                        </Fragment>
                    )}

                    {currentRequest && withDivers && (
                        <Fragment>
                            <LargeContainerTitle>Options: plongée</LargeContainerTitle>
                            <Content>
                                <RecapList>
                                    {currentRequest.divers.map((diver, i) => (
                                        <RecapListItemBullet key={i}>
                                            {diver.firstName} {diver.lastName} (ENIM {diver.enim})
                                        </RecapListItemBullet>
                                    ))}
                                </RecapList>
                            </Content>
                        </Fragment>
                    )}

                    {currentRequest && withZones && (
                        <Fragment>
                            <LargeContainerTitle>Zones demandées</LargeContainerTitle>
                            <Content>
                                {(currentRequest.targetedZones || []).sort((id1, id2) => {
                                    const zone1 = license.zones.find((z) => z.uniqueId === id1);
                                    const zone2 = license.zones.find((z) => z.uniqueId === id2);
                                    return zone1.name.localeCompare(zone2.name);
                                }).length === 0 ? (
                                    'Aucune zone demandée'
                                ) : (
                                    <RecapList>
                                        {currentRequest.targetedZones.map((zoneId) => (
                                            <RecapListItemBullet key={zoneId}>
                                                {license.zones.find((z) => z.uniqueId === zoneId).name}
                                            </RecapListItemBullet>
                                        ))}
                                    </RecapList>
                                )}
                            </Content>
                        </Fragment>
                    )}

                    <LargeContainerTitle>Paiement</LargeContainerTitle>
                    <Content>
                        <RecapList>
                            <RecapListItemWithPrice>
                                Vous devez payer votre licence auprès de votre CDPMEM soit par prélèvement automatique
                                (pensez à compléter vos coordonnées bancaires dans la section &quot;Vos infos
                                personnelles&quot;) soit par chèque à l&apos;ordre du CRPMEM de Bretagne.
                                <Price>
                                    Chèque ou Prélèvement de {currentRequest ? pricingsDetail(currentRequest) : '-'}
                                </Price>
                            </RecapListItemWithPrice>
                        </RecapList>
                    </Content>

                    <LargeContainerTitle>Pièces à fournir</LargeContainerTitle>
                    <Content>
                        <RecapList>
                            <RecapListItem>
                            Si vous ne l&apos;avez pas déjà fait ou s&apos;il est nécessaire de les mettre à jour, merci de communiquer à votre CDPMEM
                                :
                            </RecapListItem>
                            <RecapListItemBullet>Le certificat d&apos;enregistrement</RecapListItemBullet>
                            <RecapListItemBullet>Le permis d&apos;armement</RecapListItemBullet>
                            <RecapListItemBullet>La licence européenne de pêche</RecapListItemBullet>
                            <RecapListItemBullet>Le permis de navigation</RecapListItemBullet>
                            <RecapListItemBullet>
                                A défaut de prélèvement automatique, un chèque du montant de la contribution financière
                                de la licence demandée
                            </RecapListItemBullet>
                            {metaData &&
                                metaData.administrativeRequirementsAdditions &&
                                metaData.administrativeRequirementsAdditions.ownsLicenseCSJNationale && (
                                    <RecapListItemBullet>La licence CSJ nationale</RecapListItemBullet>
                                )}

                            {metaData &&
                                metaData.administrativeRequirementsAdditions &&
                                metaData.administrativeRequirementsAdditions.diversAllowedCertificate && (
                                    <RecapListItemBullet>
                                        Les certificats plongeurs (autorisation admin.)
                                    </RecapListItemBullet>
                                )}
                        </RecapList>
                    </Content>
                    <SubTitle>
                        Votre demande a bien été prise en compte. Un mail de confirmation vous sera envoyé (à
                        l&apos;adresse <a href="#">{user ? user.email : '?'}</a>) pour chaque demande (merci de vérifier
                        vos spams, surtout si vous avez fait plusieurs demandes).
                        <br />
                        <br />
                        <p>
                            - Les dossiers incomplets seront rejetés après relance du CRPMEM ou CDPMEM vous laissant 1
                            mois pour régulariser la situation.
                            <br />
                            <br />
                        </p>
                        <p>
                            - Vous pouvez consulter le statut de vos demandes dans la rubrique &quot;Campagnes en
                            cours&quot;.
                            <br />
                            <br />
                        </p>
                        <p>
                            - Les demandes effectuées sont modifiables sur votre espace personnel jusqu&apos;à la fin de
                            la période de demande. Passée cette date, contactez votre CDPMEM.
                            <br />
                            <br />
                        </p>
                        <p>
                            - Les demandes seront instruites par le CRPMEM et une notification vous sera adressée avant
                            le début de la campagne.
                        </p>
                    </SubTitle>
                </Intro>
                <BigButtonGridSpecial withMargin>
                    <ItemSelection onClick={newRequestWithDifferentCompanyOrBoat}>
                        <Chevron />
                        <ItemSelectionTitle>
                            Déposer une autre demande de licence avec une entreprise ou un navire différent
                        </ItemSelectionTitle>
                    </ItemSelection>
                    <ItemSelection
                        onClick={newRequestWithSameBoat}
                        className={classNames({ disabled: !currentRequest })}
                    >
                        <Chevron />
                        <ItemSelectionTitle>
                            Déposer une autre demande de licence avec le même navire
                        </ItemSelectionTitle>
                    </ItemSelection>
                </BigButtonGridSpecial>
            </Main>
            <Footer>
                <NextButton primary onClick={finish}>
                    Accueil
                </NextButton>
            </Footer>
        </Fragment>
    );
}
